import React, { Component } from 'react'
import { config } from 'hungry-core2'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import Loader from '@components/common/Loader'
import Modal from '@components/common/modal/Modal'
import { BiPencil, BiTrash } from 'react-icons/bi'
import { RiWallet3Line } from 'react-icons/ri'
import { IoCaretDownCircle, IoCaretForwardCircle } from 'react-icons/io5'
import { BsPerson } from 'react-icons/bs'
import { AiFillInfoCircle } from 'react-icons/ai'
import { TechHelpForm } from '@components/common'
import { valueAsNumberOrNull, isEmpty } from '~/utils'
import {
  OrderFormSectionTitle,
  Label,
  IconButton,
} from '@res/styledComponents/index'
import {
  Button,
  Checkbox,
  CurrencyInput,
  DateInput,
  Dropdown,
  Input,
  LinkText,
  RadioButton,
  TextArea,
} from '@components/common/form'
import { AutocompleteInput, PaymentMethodInput } from '@containers/common/form'
import YSpacing from '@components/common/YSpacing'
import XSpacing from '@components/common/XSpacing'
import FlexContainer from '@components/common/FlexContainer'
import DividerLine from '@components/common/DividerLine'
import { CustomizedSettingsSection } from '@components/account/edit'
import {
  colors,
  NetPaymentDays,
  AutoBatchPeriods,
  HUNGRY_CARBON_NEUTRAL_CONTRIBUTION,
  AVERAGE_PRICE_PER_PERSON_TYPES,
  INCUMBENT_OPTIONS,
  FIRST_ORDER_DATE_TYPES,
  DEFAULT_CATERING_STAFF_RATE,
  INVALID_ACCOUNT_DOMAINS,
  PAY_BY_ACH,
  PAY_BY_CC,
  PAY_BY_CHECK,
} from '../../../../constants'
import { AuthorizedInteractable } from '@containers/common/auth'
import TooltipModal from '@components/common/modal/TooltipModal'
import { MultiSelectOption } from '@res/styledComponents/index'
import AddressList from './AddressList'
import ContactList from './ContactList'

const initialState = {
  addresses: [],
  autoInvoiceBillingAddress: null,
  selectedAutoInvoiceBillingAddressId: null,
  autoBatchActive: false,
  autoBatchPeriod: 0,
  avgPricePerPerson: '',
  avgPricePerPersonType: '',
  callOnArrival: '',
  contacts: [],
  childRelationshipId: undefined,
  defaultBillingAddress: undefined,
  estimatedMonthlyValue: 0,
  id: '',
  isTaxExempt: '',
  name: '',
  needsServingUtensils: true,
  needsUtensils: true,
  netPaymentDays: 0,
  numEmployees: 0,
  expectedOrderingHeadcount: '',
  originalParentAccountId: undefined,
  parkingInstructions: '',
  payByMethod: 'Pay By Check',
  paymentMethods: [],
  parentAccount: undefined,
  referralPartners: [],
  vendorId: '',
  carbonNeutral: false,
  carbonNeutralContribution: 0,
  orderContactAutoInvoiceEmails: false,
  orderContactLatePaymentReminders: false,
  invoiceContactAutoInvoiceEmails: false,
  invoiceContactLatePaymentReminders: false,
  overdueBatchInvoiceRemindersActive: false,
  domain: '',
  requireClientReportingPrograms: false,
  trackConsumption: false,
  showFoodLabelDescription: false,

  gratuity: null,
  gratuityType: '',
  needStaffAndServe: false,
  cateringStaffCount: null,
  cateringStaffHours: null,
  cateringStaffRate: DEFAULT_CATERING_STAFF_RATE,
  cateringStaffFee: null,
  deliveryAndServiceFeePercentWeekend: null,
  deliveryAndServiceFeePercentWeekday: null,
  deliveryAndServiceFeePercentHoliday: null,
  deliveryAndServiceFeeCapWeekend: null,
  deliveryAndServiceFeeCapWeekday: null,
  deliveryAndServiceFeeCapHoliday: null,
  setUpCompleteByTime: null,
  doNotArriveBeforeTime: null,
  incumbent: '',
  clientNotes: '',
  cuisinePreferences: '',
  clientSuccessMetrics: '',
  // Track updated by
  lastUpdatedByGratuityAmount: '',
  lastUpdatedByStaffingFee: '',
  lastUpdatedByDeliveryAndServiceWeekendFee: '',
  lastUpdatedByDeliveryAndServiceWeekdayFee: '',
  lastUpdatedByDeliveryAndServiceHolidayFee: '',
  lastUpdatedBySetUpCompleteByTime: '',
  lastUpdatedByDoNotArriveBeforeTime: '',
  lastUpdatedByClientNotes: '',
  lastUpdatedByCuisinePreferences: '',
  lastUpdatedByClientSuccessMetrics: '',
  // Track updated at
  lastUpdatedAtGratuityAmount: undefined,
  lastUpdatedAtStaffingFee: undefined,
  lastUpdatedAtDeliveryAndServiceWeekendFee: undefined,
  lastUpdatedAtDeliveryAndServiceWeekdayFee: undefined,
  lastUpdatedAtDeliveryAndServiceHolidayFee: undefined,
  lastUpdatedAtSetUpCompleteByTime: undefined,
  lastUpdatedAtDoNotArriveBeforeTime: undefined,
  lastUpdatedAtClientNotes: undefined,
  lastUpdatedAtCuisinePreferences: undefined,
  lastUpdatedAtClientSuccessMetrics: undefined,

  // Track Hubspot Id Change
  hubspotIdChanged: false,

  deletedMarketplaceUsers: [],
  deletedPaymentMethods: [],
  deletedReferralPartners: [],
  groupOrderSettings: undefined,
  newMarketplaceUsers: [{}],
  newPaymentMethods: [{}],
  newReferralPartners: [{}],
  selectedPaymentMethod: undefined,
  selectedReferralPartner: undefined,
  selectedDefaultClientAutoInvoiceContact: undefined,
  clientAutoInvoiceContacts: [],
  ogClientAutoInvoiceContacts: [],
  showLoader: false,
  hubspotContactUpdates: [],
  orderingCadence: '',
  serviceDays: [],
  isCollapseCustomizedSection: true,

  newAddresses: [],
  editingAddresses: {},

  newContacts: [],
  editingContacts: {},
}
const CollectionNames = {
  defaultBillingAddress: 'addresses',
  clientAutoInvoiceContact: 'contacts',
  paymentMethod: 'paymentMethods',
  referredBy: 'referralPartners',
  mktplaceUser: 'marketplaceUsers',
}
const DeleteCollectionNames = {
  paymentMethod: 'deletedPaymentMethods',
  referredBy: 'deletedReferralPartners',
  mktplaceUser: 'deletedMarketplaceUsers',
}
const NewCollectionNames = {
  paymentMethod: 'newPaymentMethods',
  referredBy: 'newReferralPartners',
  mktplaceUser: 'newMarketplaceUsers',
  clientAutoInvoiceContact: 'newAutoInvoiceContact',
}
const SelectedResourceNames = {
  defaultBillingAddress: 'defaultBillingAddress',
  paymentMethod: 'selectedPaymentMethod',
  referredBy: 'selectedReferralPartner',
  mktplaceUser: 'selectedMarketplaceUsers',
  clientAutoInvoiceContact: 'selectedDefaultClientAutoInvoiceContact',
}

const daysOfWeek = ['M', 'T', 'W', 'TH', 'F', 'SAT', 'SUN', 'N/A']

const PayByMethods = [PAY_BY_CHECK, PAY_BY_ACH, PAY_BY_CC]

export class EditAccountModal extends Component {
  state = { ...initialState }

  async componentDidMount() {
    this.setState({ showLoader: true })
    const referralPartners = await this.props.loadReferralPartners('account')
    const { account, loadHubspotCompany } = this.props
    const { referredBy } = account
    const newState = {
      ...account,
      parentAccount: account.parentAccount || 'N/A',
    }
    if (newState.cateringStaffRate === null) {
      newState.cateringStaffRate = DEFAULT_CATERING_STAFF_RATE
    }
    newState.originalParentAccount = { ...account.parentAccount }
    if (account.addresses && account.addresses.length > 0) {
      newState.selectedAddress = account.addresses[0]
    }
    if (account.paymentMethods && account.paymentMethods.length > 0) {
      newState.selectedPaymentMethod = account.paymentMethods[0]
    }
    if (referredBy && referralPartners.length > 0) {
      const contact = referralPartners.filter(
        (r) => r.id === referredBy.contactId,
      )[0]
      const ref = { ...referredBy }
      ref.contact = contact
      newState.selectedReferralPartner = ref.contact
    }
    newState.referralPartners = referralPartners

    if (account.id) {
      loadHubspotCompany(account.id)
      this.loadGroupOrderSettings(account.pin)
      this.loadMarketplaceUsers(account.id)
    }

    newState.showLoader = false
    newState.isAlreadyTaxExempt = account.isTaxExempt

    this.setState(newState)
  }

  componentWillMount() {
    const { clearErrors } = this.props
    clearErrors()
  }

  async componentWillReceiveProps(nextProps) {
    const { account } = nextProps
    if (account !== this.props.account) {
      this.setState({
        ...account,
        parentAccount: account.parentAccount || 'N/A',
      })
      this.loadGroupOrderSettings(account.pin)
    }
  }

  loadGroupOrderSettings = async (clientPin) => {
    const groupOrderSettings = await this.props.searchClientSettings({
      clientPin,
    })
    if (groupOrderSettings && groupOrderSettings.length > 0) {
      this.setState({ groupOrderSettings })
    }
  }

  loadMarketplaceUsers = async (accountId) => {
    const marketplaceUsers = await this.props.loadAccountMarketplaceUsers(
      accountId,
      'marketplace_user',
    )
    if (marketplaceUsers) {
      this.setState({ marketplaceUsers })
    }
  }

  onAddNewResource = (resourceName) => () => {
    const collectionName = NewCollectionNames[resourceName]
    this.setState({ [collectionName]: [...this.state[collectionName], {}] })
  }

  onAddExistingResource = (resourceName) => () => {
    const collectionName = NewCollectionNames[resourceName]
    this.setState({
      [collectionName]: [...this.state[collectionName], { existing: true }],
    })
  }

  onChange = (field) => (e) => {
    let value = e
    if (value.target) {
      ;({ value } = value.target)
    }
    this.setState({ [field]: value })
  }

  onChangeResource = (resourceName, i) => (resource) => {
    const collectionName = NewCollectionNames[resourceName]
    const newResources = this.state[collectionName].slice()
    newResources[i] = resource
    this.setState({ [collectionName]: newResources })
  }

  onCancelResource = (resourceName, i) => {
    const collectionName = NewCollectionNames[resourceName]
    const newResources = this.state[collectionName].slice()
    if (newResources[i].id === undefined) {
      newResources.splice(i, 1)
    }
    this.setState({ [collectionName]: newResources })
  }

  onChangeCheckbox = (e) => {
    const { checked, value: key } = e.target
    this.setState({ [key]: checked })
  }

  onCheckCarbonNeutral = (e) => {
    const { checked } = e.target
    if (!checked) {
      this.setState({
        carbonNeutral: false,
        carbonNeutralContribution: 0,
      })
    } else {
      this.setState({
        carbonNeutral: true,
      })
    }
  }

  onChangeAutoBatchActive = async (e) => {
    const { id } = this.state
    const { flashError, validateClientAutoInvBatchable } = this.props
    const nextAutoBatchActive = e.target.checked

    if (!nextAutoBatchActive) {
      this.setState({ autoBatchActive: false })
    } else {
      const validation = await validateClientAutoInvBatchable(id)
      // only let user be able to set to active auto batch if it passes backend validation
      if (validation) {
        const { autoBatchable, error } = validation
        if (autoBatchable) {
          this.setState({ autoBatchActive: true })
        } else if (error) {
          flashError(error)
        }
      }
    }
  }

  onChangeNetPaymentAttribute = (field) => (e) => {
    const { checked, value } = e.target

    if (field === 'removeDueDateFromInv') {
      this.setState({
        [field]: checked,
      })

      return
    }

    this.setState({
      [field]: parseInt(value),
    })
  }

  onChangeRadioButton = (e) => {
    const { value: key, name } = e.target
    this.setState({ [name]: key === 'Yes' })
  }

  onChangeClientHunter = ({ id, firstName, lastName, phoneNumber }) => {
    this.setState({
      clientHunter: {
        id,
        firstName,
        lastName,
        phone: phoneNumber,
      },
      hunterId: id,
    })
  }

  onChangeClientChampion = ({ id, firstName, lastName, phoneNumber }) => {
    this.setState({
      clientChampion: {
        id,
        firstName,
        lastName,
        phone: phoneNumber,
      },
      championId: id,
    })
  }

  onChangeClientSDR = ({ id, firstName, lastName, phoneNumber }) => {
    this.setState({
      clientSDR: {
        id,
        firstName,
        lastName,
        phone: phoneNumber,
      },
    })
  }

  onChooseFile = (key) => (e) => {
    const file = e.target.files[0]
    this.setState({ [`${key}File`]: file })
  }

  onChooseLogo = (e) => {
    const logoFile = e.target.files[0]
    this.setState({ logoFile })
  }

  onDeleteResource = (resourceName) => () => {
    const resource = this.state[SelectedResourceNames[resourceName]]
    if (!resource) {
      return
    }

    const collectionName = DeleteCollectionNames[resourceName]
    const collection = this.state[collectionName].slice()
    if (!collection.find((r) => r.id === resource.id)) {
      collection.push(resource)
      this.setState({ [collectionName]: collection })
    }

    /* remove from new collection if being edited */
    const editingName = NewCollectionNames[resourceName]
    const editing = this.state[editingName].slice()
    const idx = editing.findIndex((r) => r.id === resource.id)
    if (idx !== -1) {
      editing.splice(idx, 1)
      this.setState({ [editingName]: editing })
    }
  }

  onDeleteListableResource = (resourceName) => (id) => {
    const resource = this.state[CollectionNames[resourceName]].find(
      (r) => r.id === id,
    )
    if (!resource) {
      return
    }

    const collectionName = DeleteCollectionNames[resourceName]
    const collection = this.state[collectionName].slice()
    if (!collection.find((r) => r.id === resource.id)) {
      collection.push(resource)
      this.setState({ [collectionName]: collection })
    }

    /* remove from new collection if being edited */
    const editingName = NewCollectionNames[resourceName]
    const editing = this.state[editingName].slice()
    const idx = editing.findIndex((r) => r.id === resource.id)
    if (idx !== -1) {
      editing.splice(idx, 1)
      this.setState({ [editingName]: editing })
    }
  }

  onEditResource = (resourceName) => () => {
    const resource = this.state[SelectedResourceNames[resourceName]]
    if (!resource) {
      return
    }
    const collectionName = NewCollectionNames[resourceName]
    const collection = this.state[collectionName].slice()
    /* don't add to edit twice */
    if (!collection.find((r) => r.id === resource.id)) {
      collection.push(resource)
      this.setState({ [collectionName]: collection })
    }

    /* undelete if in delete section */
    const deletedName = DeleteCollectionNames[resourceName]
    const deleted = this.state[deletedName].slice()
    const idx = deleted.findIndex((r) => r.id === resource.id)
    if (idx !== -1) {
      deleted.splice(idx, 1)
      this.setState({ [deletedName]: deleted })
    }
  }

  onEditListableResource = (resourceName) => (id) => {
    const resource = this.state[CollectionNames[resourceName]].find(
      (r) => r.id === id,
    )
    if (!resource) {
      return
    }

    const collectionName = NewCollectionNames[resourceName]
    const collection = this.state[collectionName].slice()
    /* don't add to edit twice */
    if (!collection.find((r) => r.id === resource.id)) {
      collection.push(resource)
      this.setState({ [collectionName]: collection })
    }

    /* undelete if in delete section */
    const deletedName = DeleteCollectionNames[resourceName]
    const deleted = this.state[deletedName].slice()
    const idx = deleted.findIndex((r) => r.id === resource.id)
    if (idx !== -1) {
      deleted.splice(idx, 1)
      this.setState({ [deletedName]: deleted })
    }
  }

  onHide = () => {
    this.props.close()
  }

  onSave = async () => {
    const {
      id,
      logoUrl,
      pin,
      addresses,
      newAddresses,
      newPaymentMethods,
      avgPricePerPerson,
      avgPricePerPersonType,
      autoBatchActive,
      contacts,
      newContacts,
      estimatedMonthlyValue,
      name,
      numEmployees,
      expectedOrderingHeadcount,
      parentAccount,
      groupOrderSettings,
      orderingCadence,
      serviceDays,
      needsUtensils,
      needsServingUtensils,
      clientHunter,
      clientChampion,
      sowEffectiveDate,
      incumbent,
      clientNotes,
      cuisinePreferences,
      clientSuccessMetrics,
      gratuity,
      gratuityType,
      needStaffAndServe,
      firstOrderDate,
      firstOrderDateType,
      domain,
      selectedDefaultClientAutoInvoiceContact,
      isTaxExempt,
      isAlreadyTaxExempt,
      taxExemptionDocument,
    } = this.state

    const {
      saveAccount,
      updateClientSettingsClient,
      flashError,
      flashWarning,
      validateAddress,
      hubspotCompany,
    } = this.props

    const requiredFieldErrors = []

    if (!name) {
      requiredFieldErrors.push('Account name is required')
    }

    if (!parentAccount) {
      requiredFieldErrors.push(
        'Parent account is required. Select N/A if it is not applicable.',
      )
    }
    if (!numEmployees) {
      requiredFieldErrors.push('Building/Office Population is required')
    }
    if (!expectedOrderingHeadcount) {
      requiredFieldErrors.push('Expected Ordering Headcount is required')
    }
    if (!avgPricePerPerson) {
      requiredFieldErrors.push('Budget per person is required')
    }
    if (
      avgPricePerPerson &&
      !AVERAGE_PRICE_PER_PERSON_TYPES.includes(avgPricePerPersonType)
    ) {
      requiredFieldErrors.push('Budget per person type is required')
    }
    if (!orderingCadence) {
      requiredFieldErrors.push('Ordering cadence is required')
    }
    if (orderingCadence === 'SOW Recurring' && !sowEffectiveDate) {
      requiredFieldErrors.push(
        'SOW Effective Date is required when ordering Cadence is "SOW Recurring".',
      )
    }
    if (serviceDays.length < 1) {
      requiredFieldErrors.push(
        'Service days are required.  Select N/A for Ad-Hoc/Recurring Ad-hoc clients.',
      )
    }
    if (!estimatedMonthlyValue) {
      requiredFieldErrors.push('Estimated monthly value is required')
    }
    if (gratuity === null && gratuityType) {
      requiredFieldErrors.push(
        'Cannot set gratuity type when default gratuity is not set',
      )
    }
    if (gratuity !== null && !gratuityType) {
      requiredFieldErrors.push(
        'Cannot set default gratuity when gratuity type is not set',
      )
    }
    if (needStaffAndServe && !this.validateStaffingFee()) {
      requiredFieldErrors.push(
        'Invalid values for staffing fee when need staff & serve is selected',
      )
    }
    if (needsServingUtensils === null) {
      requiredFieldErrors.push('Needs serving utensils must be selected')
    }
    if (needsUtensils === null) {
      requiredFieldErrors.push('Needs utensils must be selected')
    }
    if (!firstOrderDate) {
      requiredFieldErrors.push('First Order Date needs to be set')
    }
    if (!FIRST_ORDER_DATE_TYPES.includes(firstOrderDateType)) {
      requiredFieldErrors.push('First Order Date Type needs to be selected')
    }
    if (!clientHunter) {
      requiredFieldErrors.push('Client account executive is required')
    }
    if (!clientChampion) {
      requiredFieldErrors.push('Client success manager is required')
    }
    if (!incumbent) {
      requiredFieldErrors.push('Select an incumbent')
    }
    if (incumbent && !INCUMBENT_OPTIONS.includes(incumbent)) {
      requiredFieldErrors.push('Select a valid incumbent option')
    }

    if (!clientNotes) {
      requiredFieldErrors.push('Enter client notes')
    }
    if (clientNotes && clientNotes.trim().length < 2) {
      requiredFieldErrors.push('Enter valid client notes')
    }

    if (!cuisinePreferences) {
      requiredFieldErrors.push('Enter cuisine preferences')
    }
    if (cuisinePreferences && cuisinePreferences.trim().length < 2) {
      requiredFieldErrors.push('Enter valid cuisine preferences')
    }

    if (!clientSuccessMetrics) {
      requiredFieldErrors.push('Enter client success metrics')
    }
    if (clientSuccessMetrics && clientSuccessMetrics.trim().length < 2) {
      requiredFieldErrors.push('Enter valid client success metrics')
    }
    if (autoBatchActive && !selectedDefaultClientAutoInvoiceContact) {
      requiredFieldErrors.push(
        'Must set an auto batch invoice contact in order to activate auto-batch invoices',
      )
    }

    if (
      addresses.length < 1 &&
      (newAddresses.length === 0 ||
        newAddresses.every(
          (newAddress) => Object.keys(newAddress).length === 0,
        ))
    ) {
      requiredFieldErrors.push('Client address is required')
    }
    if (newAddresses.length > 0) {
      for (let index = 0; index < newAddresses.length; index++) {
        const address = newAddresses[index]
        const { isValid, errors } = validateAddress(address)
        if (!isValid) {
          requiredFieldErrors.push(
            `Invalid client address ${address.line1}. Errors ${JSON.stringify(
              errors,
            )}`,
          )
        }
      }
    }
    if (
      contacts.length < 1 &&
      (newContacts.length === 0 ||
        newContacts.every((newContact) => Object.keys(newContact).length === 0))
    ) {
      requiredFieldErrors.push('Client contact is required')
    }
    if (newContacts.length > 0) {
      newContacts.forEach((c) => {
        if (c.existing) {
          return
        }
        if (!c.email) {
          requiredFieldErrors.push('New Contacts must have an email')
        }
      })
    }

    if (newPaymentMethods.length > 0) {
      newPaymentMethods.forEach((pmtMethod) => {
        if (!isEmpty(pmtMethod)) {
          if (!pmtMethod.billingAddress) {
            requiredFieldErrors.push(
              `New payment method ending in ${pmtMethod.last4} must have a billing address`,
            )
          } else if (
            pmtMethod.billingAddress.line1 === '' ||
            pmtMethod.billingAddress.city === '' ||
            pmtMethod.billingAddress.zip === '' ||
            pmtMethod.billingAddress.zip === ''
          ) {
            requiredFieldErrors.push(
              `New payment method ending in ${pmtMethod.last4} must have a complete billing address`,
            )
          }
        }
      })
    }

    if (id && !isAlreadyTaxExempt && isTaxExempt && !taxExemptionDocument) {
      requiredFieldErrors.push(
        'Tax Exemption Document is required in order to mark client tax exempt',
      )
    }

    if (requiredFieldErrors.length > 0) {
      const errorList =
        'Please check the following errors:\n' +
        '• ' +
        requiredFieldErrors.join('.\n• ') +
        '.'
      flashError(errorList)

      return
    }

    const data = this.state
    if (domain === '' && newContacts.length > 0) {
      if (
        newContacts.some(
          (contact) =>
            contact.email && !contact.email.match(INVALID_ACCOUNT_DOMAINS),
        )
      ) {
        data.domain = newContacts
          .find(
            (contact) =>
              contact.email && !contact.email.match(INVALID_ACCOUNT_DOMAINS),
          )
          .email.split('@')[1]
        flashWarning('Account domain has been updated')
      }
    }
    data.hasCCs = this.state.paymentMethods.length > 0

    data.needHubspotSync = false
    if (hubspotCompany && hubspotCompany.hubspotId) {
      data.hubspotId = hubspotCompany.hubspotId
      if (hubspotCompany.needHubspotSync) {
        data.needHubspotSync = true
      }
    }

    await saveAccount(data)
    if (groupOrderSettings) {
      groupOrderSettings.forEach((settings) => {
        updateClientSettingsClient(settings.id, name, {
          id,
          logoUrl,
          pin,
          name,
        })
      })
    }
  }

  onSaveLogo = async () => {
    const { logoFile } = this.state
    const logoUrl = await this.props.uploadAccountLogoImage(logoFile)
    if (logoUrl) {
      this.setState({ logoFile: undefined, logoUrl })
    }
  }

  onSaveAccountDocument = (documentKey) => async () => {
    this.setState({ [`${documentKey}FileSaving`]: true })
    const { id } = this.state
    const file = this.state[`${documentKey}File`]
    const url = await this.props.createAccountDocument(file, id, documentKey)
    if (url) {
      this.setState({
        [`${documentKey}FileSaving`]: false,
        [`${documentKey}File`]: undefined,
        [documentKey]: url,
      })
    }
  }

  onSelectExistingResource = (resourceName, i) => (resource) => {
    const collectionName = NewCollectionNames[resourceName]
    const newResources = this.state[collectionName].slice()
    newResources[i] = { ...resource, existing: true }
    this.setState({ [collectionName]: newResources })
  }

  onSelectParentAccount = (parentAccount) => {
    if (parentAccount.name === 'Remove Parent Link') {
      if (
        this.state.originalParentAccount &&
        this.state.parentAccount.id == this.state.originalParentAccount.id
      ) {
        this.setState({ deletedParentAccount: { ...this.state.parentAccount } })
      }
    } else if (parentAccount.name === 'N/A') {
      if (
        this.state.parentAccount?.id &&
        this.state.originalParentAccount?.id &&
        this.state.parentAccount.id === this.state.originalParentAccount.id
      ) {
        this.setState({
          deletedParentAccount: { ...this.state.parentAccount },
          parentAccount: 'N/A',
        })
      } else {
        this.setState({ parentAccount: 'N/A' })
      }
    } else {
      this.setState({ parentAccount, deletedParentAccount: null })
    }
  }

  onSelectOrderingCadence = (e) => {
    const orderingCadence = e.target.value
    this.setState({ orderingCadence })
  }

  onSelectOrderingFrequency = (e) => {
    const orderingFrequency = e.target.value
    this.setState({ orderingFrequency })
  }

  onChangeServiceDays = (day) => {
    const { serviceDays } = this.state
    let days = []
    if (serviceDays) {
      days = serviceDays
    }
    if (days.includes(day)) {
      days = days.filter((e) => e !== day)
    } else {
      days.push(day)
    }

    this.setState({ serviceDays: [...days] })
  }

  onSelectResource = (resourceName) => (e) => {
    const resourceId = e.target.value
    const key = SelectedResourceNames[resourceName]
    const collection = this.state[CollectionNames[resourceName]]
    const resource = collection.find((r) => r.id === resourceId)
    this.setState({ [key]: resource })
  }

  onUndeleteResource = (resourceName, i) => () => {
    const collectionName = DeleteCollectionNames[resourceName]
    const collection = this.state[collectionName].slice()
    collection.splice(i, 1)
    this.setState({ [collectionName]: collection })
  }

  onSelectHubspotCompany = (c) => {
    this.setState({ hubspotContact: c, hubspotIdChanged: true }, () =>
      this.props.updateHubspotCompanyRef(this.state.id, c.id, c.name),
    )
  }

  onSelectAutoInvoiceBillingAddress = (e) => {
    const { addresses } = this.state
    const { getAutoInvoiceBillingAddress } = this.props
    const addrId = e.target.value
    const address = addresses.find((addr) => addr.id === addrId)
    if (!address) {
      return
    }
    const { line1, line2, city, state, zip } = address
    const newState = {
      autoInvoiceBillingLine1: line1,
      autoInvoiceBillingLine2: line2,
      autoInvoiceBillingCity: city,
      autoInvoiceBillingState: state,
      autoInvoiceBillingZip: zip,
      autoInvoiceBillingAddress: '',
    }
    newState.autoInvoiceBillingAddress = getAutoInvoiceBillingAddress(newState)
    this.setState(newState)
  }

  onChangeDomain = (domain) => {
    this.setState({ domain })
  }

  getDomainsFromContacts = () => {
    const { contacts } = this.state

    return contacts
      .filter((contact) => {
        if (
          !contact.email ||
          !contact.email.includes('@') ||
          contact.email.match(INVALID_ACCOUNT_DOMAINS)
        ) {
          return false
        }

        return true
      })
      .map((contact) => contact.email.split('@')[1])
  }

  renderEditAuthedComponent = (roles, component) => {
    const { id } = this.state

    if (!id) {
      return component
    }

    return (
      <AuthorizedInteractable roles={roles} customStyle={{ width: '100%' }}>
        {component}
      </AuthorizedInteractable>
    )
  }

  renderResourceDeleted = (resourceName, testId) => {
    const collection = this.state[DeleteCollectionNames[resourceName]]
    if (collection.length === 0) {
      return null
    }

    return (
      <div className="form-field-container__columns delete-list">
        <YSpacing height="10px" />
        <Label>Marked for Deletion</Label>
        <div>
          {collection.map((r, i) => (
            <div key={i} className="columns-container">
              ―<span> {r.fullAddress || r.name || r.shortName}</span>
              <button
                className="cancel-button"
                id={`${testId}-unmark-delete`}
                onClick={this.onUndeleteResource(resourceName, i)}
              >
                Unmark Delete
              </button>
            </div>
          ))}
        </div>
      </div>
    )
  }

  renderParentAccountDropdown = () => {
    const { loadAccounts } = this.props
    const { id, parentAccount, originalParentAccount } = this.state
    let selectedValue
    if (this.state.deletedParentAccount) {
      selectedValue = 'Remove Parent Link'
    } else if (this.state.parentAccount && this.state.parentAccount.id) {
      selectedValue = this.state.parentAccount.name
    } else if (this.state.parentAccount === 'N/A') {
      selectedValue = 'N/A'
    } else {
      selectedValue = 'Select'
    }

    const defaultOptions = [{ name: 'N/A', value: 'N/A' }]
    if (
      parentAccount?.id &&
      originalParentAccount?.id &&
      parentAccount.id === originalParentAccount.id
    ) {
      defaultOptions.push({
        name: 'Remove Parent Link',
        value: 'Remove Parent Link',
      })
    }

    if (!id) {
      return (
        <div style={{ width: '31%', paddingTop: '10px' }}>
          <strong>
            Please save the account before linking it to a parent account.
          </strong>
        </div>
      )
    }

    return (
      <AutocompleteInput
        width="31%"
        marginBottom="20px"
        defaultOptions={defaultOptions}
        value={selectedValue}
        label="Link to Parent Account"
        loaderFunction={loadAccounts}
        filters={{ all_hqs: true }}
        onSelect={this.onSelectParentAccount}
      />
    )
  }

  renderAddressList = () => {
    const {
      id: accountId,
      addresses,
      newAddresses,
      editingAddresses,
    } = this.state
    const { currentUser, host, dinerProfiles } = this.props

    const updatedBy = `${currentUser.firstName} ${currentUser.lastName}`

    return (
      <>
        <AddressList
          accountId={accountId}
          dinerProfiles={dinerProfiles}
          host={host}
          addresses={addresses}
          newAddresses={newAddresses}
          editingAddresses={editingAddresses}
          onAddNewAddress={() =>
            this.setState({
              newAddresses: [...newAddresses, { key: newAddresses.length }],
            })
          }
          onChangeNewAddress={(address, key) => {
            const newAddrs = newAddresses.slice()
            newAddrs[key] = {
              ...address,
              key,
              updatedBy,
            }
            this.setState({ newAddresses: newAddrs })
          }}
          onCancelNewAddress={(key) => {
            const newAddrs = newAddresses.slice()
            newAddrs.splice(key, 1)
            newAddrs.forEach((a, i) => {
              a.key = i
            })
            this.setState({ newAddresses: newAddrs })
          }}
          onEditAddress={(address) =>
            this.setState({
              editingAddresses: {
                ...editingAddresses,
                [address.id]: address,
              },
            })
          }
          onChangeEditAddress={(address) => {
            const newEdit = { ...editingAddresses }
            newEdit[address.id] = {
              ...address,
              updatedBy,
            }
            this.setState({ editingAddresses: newEdit })
          }}
          onCancelEditAddress={(id) => {
            const newEdit = { ...editingAddresses }
            delete newEdit[id]
            this.setState({ editingAddresses: newEdit })
          }}
          onDeleteAddress={this.props.deleteAccountAddress}
          onSaveAddress={this.onSaveAccountAddress}
          markDefaultAddress={this.props.markDefaultAccountAddress}
        />
        <YSpacing height="20px" />
      </>
    )
  }

  renderContactList = () => {
    const {
      id: accountId,
      contacts,
      accountContacts,
      newContacts,
      editingContacts,
    } = this.state
    const {
      currentUser,
      errors,
      host,
      hubspotContact,
      loadAccountContacts,
      loadHubspotContact,
      loadHubspotContacts,
      updateHubspotContactRef,
      dinerProfiles,
    } = this.props

    const updatedBy = `${currentUser.firstName} ${currentUser.lastName}`

    return (
      <>
        <ContactList
          accountId={accountId}
          dinerProfiles={dinerProfiles}
          host={host}
          contacts={contacts}
          accountContacts={accountContacts}
          newContacts={newContacts}
          editingContacts={editingContacts}
          hubspotContact={hubspotContact}
          loadAccountContacts={loadAccountContacts}
          loadHubspotContact={loadHubspotContact}
          loadHubspotContacts={loadHubspotContacts}
          updateHubspotContactRef={updateHubspotContactRef}
          onAddNewContact={() =>
            this.setState({
              newContacts: [...newContacts, { key: newContacts.length }],
            })
          }
          onAddExistingContact={() =>
            this.setState({
              newContacts: [
                ...newContacts,
                { existing: true, key: newContacts.length },
              ],
            })
          }
          onSelectExistingContact={(contact, key) => {
            const newConts = newContacts.slice()
            newConts[key] = {
              ...contact,
              key,
              existing: true,
            }
            this.setState({ newContacts: newConts })
          }}
          onChangeNewContact={(contact, key) => {
            const newConts = newContacts.slice()
            newConts[key] = {
              ...contact,
              key,
              updatedBy,
            }
            this.setState({ newContacts: newConts })
          }}
          onCancelNewContact={(key) => {
            const newConts = newContacts.slice()
            newConts.splice(key, 1)
            newConts.forEach((c, i) => {
              c.key = i
            })
            this.setState({ newContacts: newConts })
          }}
          onEditContact={(contact) =>
            this.setState({
              editingContacts: {
                ...editingContacts,
                [contact.id]: contact,
              },
            })
          }
          onChangeEditContact={(contact) => {
            const newEdit = { ...editingContacts }
            newEdit[contact.id] = {
              ...contact,
              updatedBy,
            }
            this.setState({ editingContacts: newEdit })
          }}
          onCancelEditContact={(id) => {
            const newEdit = { ...editingContacts }
            delete newEdit[id]
            this.setState({ editingContacts: newEdit })
          }}
          onDeleteContact={this.props.deleteAccountContact}
          onSaveContact={this.onSaveAccountContact}
          markDefaultContact={this.props.markDefaultAccountContact}
          errors={errors}
        />
        <YSpacing height="20px" />
      </>
    )
  }

  onSaveAccountAddress = async (address) => {
    const { id: accountId, editingAddresses } = this.state

    const success = await this.props.updateAccountAddress(accountId, {
      ...address,
      accountId: this.state.id,
    })

    if (success) {
      const newAddresses = { ...editingAddresses }
      delete newAddresses[address.id]
      this.setState({ editingAddresses: newAddresses })
    }
  }

  onSaveAccountContact = async (contact) => {
    const { id: accountId, editingContacts } = this.state
    const { id: contactId, accountContactId } = contact

    const success = await this.props.updateAccountContact(accountId, {
      ...contact,
      phone: contact.phoneNumber,
      accountId,
      id: accountContactId,
      contactId,
    })
    if (success) {
      const newContacts = { ...editingContacts }
      delete newContacts[contact.id]
      this.setState({ editingContacts: newContacts })
    }
  }

  onSavePaymentMethod = (resourceName) => async (paymentMethod) => {
    const { id: accountId } = this.state
    const { updatePaymentMethod } = this.props
    const savedPaymentMethod = await updatePaymentMethod(
      accountId,
      paymentMethod,
    )
    if (savedPaymentMethod) {
      const editCollectionName = NewCollectionNames[resourceName]
      const newCollection = this.state[editCollectionName].slice()
      const indexToRemove = newCollection.findIndex(
        (item) => item.id === savedPaymentMethod.id,
      )
      if (indexToRemove !== -1) {
        newCollection.splice(indexToRemove, 1)
      }
      this.setState({
        [editCollectionName]: newCollection,
        selectedPaymentMethod: savedPaymentMethod,
      })
    }
  }

  renderResourceDropdown = (resourceName, label, testId) => {
    let collection = this.state[CollectionNames[resourceName]] || []
    collection = [{}, ...collection]
    const selectedResource =
      this.state[SelectedResourceNames[resourceName]] || {}

    return (
      <div style={{ width: '100%' }}>
        <Dropdown
          label={`Select Existing ${label}`}
          width="100%"
          marginBottom="5px"
          testId={testId}
          value={selectedResource.id}
          onChange={this.onSelectResource(resourceName)}
        >
          {collection.map((r) => (
            <option key={r.id} value={r.id}>
              {r.fullAddress || r.name || r.shortName}
            </option>
          ))}
        </Dropdown>
        {(resourceName === 'mktplaceUser' ||
          resourceName === 'paymentMethod') &&
          resourceName !== 'clientAutoInvoiceContact' &&
          collection.filter((c) => c.id).length > 0 && (
            <FlexContainer flexDirection="column">
              {resourceName === 'paymentMethod' && (
                <IconButton
                  id={`${testId}-edit`}
                  onClick={this.onEditResource(resourceName)}
                >
                  <BiPencil />
                  Edit
                </IconButton>
              )}
              <IconButton
                id={`${testId}-delete`}
                onClick={this.onDeleteResource(resourceName)}
              >
                <BiTrash /> Mark Delete
              </IconButton>
            </FlexContainer>
          )}
      </div>
    )
  }

  renderResourceInputs = (resourceName) => {
    const { errors } = this.props

    const collection = this.state[NewCollectionNames[resourceName]]
    let renderInput
    if (resourceName === 'mktplaceUser') {
      renderInput = (resource, i) =>
        this.renderMktplaceUserInput(resourceName, resource, i, errors)
    } else if (resourceName === 'paymentMethod') {
      renderInput = (resource, i) => (
        <PaymentMethodInput
          hasBackground={true}
          key={i}
          paymentMethod={resource}
          showSaveButton={true}
          onChange={this.onChangeResource(resourceName, i)}
          onCancel={() => this.onCancelResource(resourceName, i)}
          onSave={this.onSavePaymentMethod(resourceName)}
        />
      )
    }

    return collection.map(renderInput)
  }

  renderMktplaceUserInput = (resourceName, resource, i, errors) => {
    const { loadMktplaceUsers } = this.props
    const userName =
      resource.firstName && resource.lastName
        ? resource.firstName + '' + resource.lastName
        : ''
    const isExisting = resource.existing === true

    if (isExisting) {
      return (
        <div>
          <YSpacing height="20px" />
          <AutocompleteInput
            width="100%"
            marginBottom="20px"
            value={userName}
            label="Select a Marketplace User"
            error={errors.mktplaceUser}
            loaderFunction={loadMktplaceUsers}
            onSelect={this.onSelectExistingResource(resourceName, i)}
          />
        </div>
      )
    }
  }

  renderNetPaymentSettingsInput = () => {
    const {
      netPaymentDays,
      autoBatchPeriod,
      payByMethod,
      removeDueDateFromInv,
    } = this.state

    return (
      <div>
        <OrderFormSectionTitle>Payment Settings</OrderFormSectionTitle>
        <YSpacing height="20px" />
        <FlexContainer
          width="100%"
          justifyContent="flex-start"
          alignItems="flex-end"
        >
          {this.renderEditAuthedComponent(
            ['master admin', 'finance', 'sales rep', 'sales lead'],
            <Dropdown
              defaultValue="none"
              label="Net Payment Terms"
              testId="net-payment-days"
              width="31%"
              marginBottom="0"
              value={netPaymentDays || 0}
              onChange={this.onChangeNetPaymentAttribute('netPaymentDays')}
            >
              {Object.keys(NetPaymentDays).map((p) => (
                <option key={p} value={p}>
                  {NetPaymentDays[p]}
                </option>
              ))}
            </Dropdown>,
          )}
          <XSpacing width="10px" />
          {this.renderEditAuthedComponent(
            ['master admin', 'finance', 'sales rep', 'sales lead'],
            <Dropdown
              label="Pay By Method"
              width="31%"
              testId="pay-by-method"
              marginBottom="0px"
              value={payByMethod}
              onChange={(e) =>
                this.onChangeGeneric('payByMethod', e.target.value)
              }
            >
              {PayByMethods.map((m, i) => (
                <option key={i} value={m}>
                  {m}
                </option>
              ))}
            </Dropdown>,
          )}
          <FlexContainer width="100%" alignItems="center">
            <Info>
              <AiFillInfoCircle color="black" size={20} />
              <PopOver className="tooltip">
                <p>
                  Weekly = Invoice includes orders Mon - Sun; Sent the following
                  Monday
                </p>
                <p>
                  Semi-Monthly = 1st invoice includes orders on the 1st - 15th
                  of the month; Sent the 16th
                  <p>
                    of the month. 2nd invoice includes orders 16th - the last
                    day of the month; Sent the 1st
                  </p>
                  <p>of the following month</p>
                </p>
                <p>
                  {
                    "Monthly = Invoice includes the month's orders, sent on the 1st of the following month"
                  }
                </p>
              </PopOver>
            </Info>
            {this.renderEditAuthedComponent(
              ['master admin', 'finance', 'sales rep', 'sales lead'],
              <Dropdown
                marginBottom="0"
                defaultValue="none"
                label="Invoicing Cadence"
                testId="invoice-cadence"
                width="90%"
                value={autoBatchPeriod || 0}
                onChange={this.onChangeNetPaymentAttribute('autoBatchPeriod')}
              >
                {Object.keys(AutoBatchPeriods).map((p) => (
                  <option key={p} value={p}>
                    {AutoBatchPeriods[p]}
                  </option>
                ))}
              </Dropdown>,
            )}
          </FlexContainer>
        </FlexContainer>
        <YSpacing height="20px" />
        <FlexContainer
          width="100%"
          justifyContent="flex-start"
          alignItems="flex-end"
        >
          {this.renderEditAuthedComponent(
            [
              'master admin',
              'finance',
              'accounting',
              'sales rep',
              'sales lead',
            ],
            <Checkbox
              label="Remove Due Date From Invoices"
              checked={removeDueDateFromInv}
              onChange={this.onChangeNetPaymentAttribute(
                'removeDueDateFromInv',
              )}
            />,
          )}
        </FlexContainer>
      </div>
    )
  }

  renderAutoBatchInvSettingsInput = () => {
    const {
      id,
      addresses,
      autoBatchActive,
      autoInvoiceBillingAddress,
      selectedAutoInvoiceBillingAddressId,
      contacts,
      clientAutoInvoiceContacts,
      overdueBatchInvoiceRemindersActive,
      selectedDefaultClientAutoInvoiceContact,
    } = this.state
    const { pContactToAccountingContact } = this.props
    const selectedContacts = [...clientAutoInvoiceContacts]
    if (selectedDefaultClientAutoInvoiceContact) {
      selectedContacts.push(selectedDefaultClientAutoInvoiceContact)
    }
    const selectableContacts = contacts.filter(
      (c) => !selectedContacts.find((selected) => selected.id === c.id),
    )

    return (
      <div>
        <OrderFormSectionTitle>
          Auto-Batch Invoice Settings
        </OrderFormSectionTitle>
        <p>
          Batches orders for any unpaid amount, with an effective date before
          the batching date, and not previously auto-batched
        </p>
        <YSpacing height="20px" />

        {!id && (
          <p>
            Auto-batch invoice configurations can only edited after first
            creating the client profile
          </p>
        )}

        {id && (
          <>
            <FlexContainer alignItems="flex-end">
              {this.renderEditAuthedComponent(
                [
                  'master admin',
                  'finance',
                  'accounting',
                  'sales rep',
                  'sales lead',
                ],
                <Checkbox
                  width="90%"
                  label="Activate Auto-Batch Invoices"
                  value="autoBatchActive"
                  checked={autoBatchActive}
                  onChange={this.onChangeAutoBatchActive}
                />,
              )}
              <XSpacing width="10px" />
              {this.renderEditAuthedComponent(
                [
                  'master admin',
                  'finance',
                  'accounting',
                  'sales rep',
                  'sales lead',
                ],
                <Checkbox
                  label="Overdue Batch Invoice Reminders?"
                  value="overdueBatchInvoiceRemindersActive"
                  checked={overdueBatchInvoiceRemindersActive}
                  onChange={this.onChangeCheckbox}
                />,
              )}
            </FlexContainer>
            <YSpacing height="20px" />
            {autoInvoiceBillingAddress ? (
              <div className="flex flex-col">
                <span className="bold uppercase">
                  Default Auto Invoice Billing Address
                </span>
                <div className="flex flex-row">
                  <span className="mr-10">{autoInvoiceBillingAddress}</span>
                  <IconButton
                    id="selectAutoInvBillingAddrDelete"
                    onClick={() =>
                      this.setState({
                        autoInvoiceBillingLine1: '',
                        autoInvoiceBillingLine2: '',
                        autoInvoiceBillingCity: '',
                        autoInvoiceBillingState: '',
                        autoInvoiceBillingZip: '',
                        autoInvoiceBillingAddress: '',
                      })
                    }
                  >
                    <BiTrash /> Mark Delete
                  </IconButton>
                </div>
              </div>
            ) : (
              <div className="w-1/2">
                <Dropdown
                  label="Select Existing Default Auto Invoice Billing Address"
                  width="100%"
                  marginBottom="5px"
                  testId="selectAutoInvBillingAddr"
                  value={selectedAutoInvoiceBillingAddressId}
                  onChange={this.onSelectAutoInvoiceBillingAddress}
                >
                  <option> - </option>
                  {addresses.map((r) => (
                    <option key={r.id} value={r.id}>
                      {r.fullAddress}
                    </option>
                  ))}
                </Dropdown>
              </div>
            )}
            <YSpacing height="20px" />
            <div>
              Default contact is who the batch invoice is addressed to. All
              default and additional contacts are included on the email.
            </div>
            <FlexContainer alignItems="flex-end">
              <div className="w-1/2">
                {this.renderResourceDropdown(
                  'clientAutoInvoiceContact',
                  'Default Auto Invoice Contact',
                  'select-auto-invoice-contact',
                )}
              </div>
            </FlexContainer>
            <YSpacing height="20px" />
            <div>
              First add a contact and save the account before it can be used as
              an additional contact.
            </div>
            <div className="w-1/2">
              {this.renderEditAuthedComponent(
                [
                  'master admin',
                  'finance',
                  'accounting',
                  'sales rep',
                  'sales lead',
                ],
                <Dropdown
                  label="Additional Auto-Invoice Contacts"
                  width="100%"
                  value=""
                  defaultValue=""
                  marginBottom="0"
                  onChange={(e) =>
                    this.setState({
                      clientAutoInvoiceContacts: [
                        ...clientAutoInvoiceContacts,
                        pContactToAccountingContact(
                          selectableContacts.find(
                            (c) => c.id === e.target.value,
                          ),
                        ),
                      ],
                    })
                  }
                >
                  <option value="" disabled hidden>
                    - Select -
                  </option>
                  {selectableContacts.map((contact) => (
                    <option key={contact.id} value={contact.id}>
                      {contact.name}
                    </option>
                  ))}
                </Dropdown>,
              )}
            </div>
            <YSpacing height="5px" />
            <FlexContainer flexWrap="wrap">
              {clientAutoInvoiceContacts.map((contact, idx) => (
                <MultiSelectOption
                  key={contact.id}
                  onClick={() =>
                    this.setState({
                      clientAutoInvoiceContacts: [
                        ...clientAutoInvoiceContacts.slice(0, idx),
                        ...clientAutoInvoiceContacts.slice(idx + 1),
                      ],
                    })
                  }
                >
                  {contact.name}
                </MultiSelectOption>
              ))}
            </FlexContainer>
          </>
        )}
      </div>
    )
  }

  renderCateringReminderSettingsInput = () => {
    const {
      orderContactAutoInvoiceEmails,
      orderContactLatePaymentReminders,
      invoiceContactAutoInvoiceEmails,
      invoiceContactLatePaymentReminders,
    } = this.state

    return (
      <div>
        <OrderFormSectionTitle>
          Catering Reminder Settings
        </OrderFormSectionTitle>
        <YSpacing height="20px" />
        <FlexContainer flexDirection="row">
          <FlexContainer flexDirection="column">
            <YSpacing height="10px" />
            <Checkbox
              label="Overdue Catering Reminders (Order Contact)?"
              value="orderContactLatePaymentReminders"
              checked={orderContactLatePaymentReminders}
              onChange={this.onChangeCheckbox}
            />
            <YSpacing height="10px" />
            <Checkbox
              label="Overdue Catering Reminders (Invoice Contact)?"
              value="invoiceContactLatePaymentReminders"
              checked={invoiceContactLatePaymentReminders}
              onChange={this.onChangeCheckbox}
            />
          </FlexContainer>
          <FlexContainer flexDirection="column">
            <Checkbox
              label="Automatic Invoice Emails (Order Contact)?"
              value="orderContactAutoInvoiceEmails"
              checked={orderContactAutoInvoiceEmails}
              onChange={this.onChangeCheckbox}
            />
            <YSpacing height="10px" />
            <Checkbox
              label="Automatic Invoice Emails (Invoice Contact)?"
              value="invoiceContactAutoInvoiceEmails"
              checked={invoiceContactAutoInvoiceEmails}
              onChange={this.onChangeCheckbox}
            />
          </FlexContainer>
        </FlexContainer>
      </div>
    )
  }

  renderCarbonNeutralTooltip = () => (
    <p>
      It costs $10 to make a single Catering order carbon neutral. Select the{' '}
      {"client's"} contribution to automatically default all future Catering
      orders with this contribution amount.
    </p>
  )

  renderLatePaymentTooltip = () => (
    <p>
      Clients will only receive a late payment reminder if this is checked and
      their ordering cadence is neither SOW Adhoc or SOW Recurring.
    </p>
  )

  renderAutoInvoiceTooltip = () => (
    <p>
      Recieving of automatic invoice emails is limited to clients who do not
      have {"'Pay By Check'"} and {"'Pay by Check'"} selected.
    </p>
  )

  calculateCarbonContrib = (carbonContribPercent) => {
    return HUNGRY_CARBON_NEUTRAL_CONTRIBUTION * (carbonContribPercent / 100)
  }

  renderCarbonContribButton = (carbonContribPercent) => {
    const onClick = (e) => {
      e.preventDefault()
      e.stopPropagation()

      const carbonNeutralContribution =
        this.calculateCarbonContrib(carbonContribPercent)
      this.setState({ carbonNeutralContribution })
    }

    return (
      <FlexContainer alignItems="flex-start" width="50px">
        <LinkText
          key={carbonContribPercent}
          onClick={onClick}
          label={`${carbonContribPercent}%`}
        />
        <XSpacing width="15px" />
      </FlexContainer>
    )
  }

  onChangeCarbonNeutralContrib = (value) => {
    if (value >= HUNGRY_CARBON_NEUTRAL_CONTRIBUTION) {
      this.setState({ carbonNeutralContribution: 10.0 })
    } else {
      this.setState({ carbonNeutralContribution: value })
    }
  }

  onChangeGeneric = (field, value) => {
    this.setState({ [field]: value })
  }

  onChangeStaffingFeeFields = (field) => (e) => {
    this.setState(
      { [field]: e.target.valueAsNumber },
      this.recalculateDefaultStaffingFee,
    )
  }

  recalculateDefaultStaffingFee = () => {
    const { cateringStaffCount, cateringStaffHours, cateringStaffRate } =
      this.state

    if (cateringStaffCount && cateringStaffHours && cateringStaffRate) {
      const total = cateringStaffCount * cateringStaffHours * cateringStaffRate

      this.setState({ cateringStaffFee: total })
    } else {
      this.setState({ cateringStaffFee: 0.0 })
    }
  }

  validateStaffingFee = () => {
    const {
      cateringStaffCount,
      cateringStaffHours,
      cateringStaffRate,
      cateringStaffFee,
    } = this.state

    if (
      cateringStaffCount &&
      cateringStaffHours &&
      cateringStaffRate &&
      cateringStaffFee
    ) {
      const total = cateringStaffCount * cateringStaffHours * cateringStaffRate

      if (total === cateringStaffFee) {
        return true
      }
    }

    return false
  }

  renderIncumbentDropDown() {
    const { incumbent } = this.state

    return (
      <Dropdown
        label={'*Incumbent'}
        width="100%"
        marginBottom="5px"
        testId={'incumbent-drop-down'}
        value={incumbent}
        onChange={(e) => this.onChangeGeneric('incumbent', e.target.value)}
      >
        <option key={-1} value="">
          Select
        </option>
        {INCUMBENT_OPTIONS.map((r) => (
          <option key={r} value={r}>
            {r}
          </option>
        ))}
      </Dropdown>
    )
  }

  renderLastUpdated = (by, at) => {
    return <p>Last Updated By: {`${by || ''} ${at || ''}`}</p>
  }

  renderDocumentSaveButton = (documentKey) => {
    const {
      [`${documentKey}FileSaving`]: saving,
      [`${documentKey}File`]: file,
    } = this.state

    return file ? (
      <LinkText
        label={saving ? 'Saving...' : 'Save'}
        disabled={saving}
        onClick={this.onSaveAccountDocument(documentKey)}
        color={colors.blue400}
      />
    ) : null
  }

  render() {
    const { loadAccountExecutives } = this.props
    const {
      addresses,
      avgPricePerPerson,
      avgPricePerPersonType,
      callOnArrival,
      defaultBillingAddress,
      estimatedMonthlyValue,
      id,
      isTaxExempt,
      name,
      needsServingUtensils,
      needsUtensils,
      numEmployees,
      expectedOrderingHeadcount,
      vendorId,
      carbonNeutral,
      carbonNeutralContribution,
      requireClientReportingPrograms,
      trackConsumption,
      showFoodLabelDescription,
      domain,
      showLoader,
      taxExemptionDocument,
      statementOfWork,
      logoFile,
      clientChampion,
      clientHunter,
      clientSDR,
      firstOrderDate,
      firstOrderDateType,
      logoUrl,
      orderingCadence,
      orderingFrequency,
      serviceDays,
      sowEffectiveDate,
      gratuityType,
      gratuity,
      hideFromMapView,
      needStaffAndServe,
      cateringStaffCount,
      cateringStaffHours,
      cateringStaffRate,
      cateringStaffFee,
      deliveryAndServiceFeePercentWeekend,
      deliveryAndServiceFeePercentWeekday,
      deliveryAndServiceFeePercentHoliday,
      deliveryAndServiceFeeCapWeekend,
      deliveryAndServiceFeeCapWeekday,
      deliveryAndServiceFeeCapHoliday,
      doNotArriveBeforeTime,
      setUpCompleteByTime,
      clientNotes,
      cuisinePreferences,
      clientSuccessMetrics,
      lastUpdatedByGratuityAmount,
      lastUpdatedByStaffingFee,
      lastUpdatedByDeliveryAndServiceWeekendFee,
      lastUpdatedByDeliveryAndServiceWeekdayFee,
      lastUpdatedByDeliveryAndServiceHolidayFee,
      lastUpdatedBySetUpCompleteByTime,
      lastUpdatedByDoNotArriveBeforeTime,
      lastUpdatedByClientNotes,
      lastUpdatedByCuisinePreferences,
      lastUpdatedByClientSuccessMetrics,
      lastUpdatedAtGratuityAmount,
      lastUpdatedAtStaffingFee,
      lastUpdatedAtDeliveryAndServiceWeekendFee,
      lastUpdatedAtDeliveryAndServiceWeekdayFee,
      lastUpdatedAtDeliveryAndServiceHolidayFee,
      lastUpdatedAtSetUpCompleteByTime,
      lastUpdatedAtDoNotArriveBeforeTime,
      lastUpdatedAtClientNotes,
      lastUpdatedAtCuisinePreferences,
      lastUpdatedAtClientSuccessMetrics,
      isCollapseCustomizedSection,
    } = this.state
    const defaultAddressList = [{}, ...addresses]

    return (
      <Modal
        title={id ? `Edit ${name}` : 'New Account'}
        hideModal={this.onHide}
        color="#001940"
        width="900px"
      >
        {showLoader ? (
          <Loader isCenter={true} />
        ) : (
          <div>
            <OrderFormSectionTitle>
              Account General Details
            </OrderFormSectionTitle>
            <p>* Indicates required fields</p>
            <YSpacing height="20px" />
            <FlexContainer width="100%" justifyContent="space-between">
              <Input
                width="31%"
                label="*Account Name"
                value={name}
                testId="account"
                onChange={this.onChange('name')}
              />
              {this.renderParentAccountDropdown()}
              <Input
                width="31%"
                label="Vendor Id (optional)"
                value={vendorId}
                testId="vendor-id"
                onChange={this.onChange('vendorId')}
              />
            </FlexContainer>

            <FlexContainer width="100%" justifyContent="space-between">
              {this.props.hubspotCompany && this.props.hubspotCompany.name ? (
                <FlexContainer width="31%">
                  <a
                    className="order-link"
                    href={`https://${config.hubspot_url_prefix}/company/${this.props.hubspotCompany.hubspotId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {this.props.hubspotCompany.name}
                  </a>
                  <TooltipModal
                    unicode="&#9432;"
                    width="400px"
                    marginTop="20px"
                    information="If you need to change the associated between this client & the hubspot company. Please reach out to the tech team to handle this request."
                  />
                </FlexContainer>
              ) : (
                <AutocompleteInput
                  width="31%"
                  label="Link to Hubspot Company"
                  displayAttributes={['name', 'city', 'state', 'domain']}
                  value={
                    this.props.hubspotCompany && this.props.hubspotCompany.name
                  }
                  includePagination={false}
                  searchDelay={2000}
                  refreshOnSelect={false}
                  loaderFunction={this.props.loadHubspotCompanyById}
                  onSelect={this.onSelectHubspotCompany}
                />
              )}
              <Input
                width="31%"
                label="*Building/Office Population"
                type="number"
                value={numEmployees}
                testId="number-of-employees"
                onChange={(e) =>
                  this.onChangeGeneric(
                    'numEmployees',
                    valueAsNumberOrNull(e.target.valueAsNumber),
                  )
                }
              />
              <Input
                width="31%"
                type="number"
                label="*Expected Ordering Headcount"
                value={expectedOrderingHeadcount}
                testId="expected-ordering-headcount"
                onChange={(e) =>
                  this.onChangeGeneric(
                    'expectedOrderingHeadcount',
                    valueAsNumberOrNull(e.target.valueAsNumber),
                  )
                }
              />
            </FlexContainer>
            <FlexContainer width="100%" justifyContent="space-between">
              <FlexContainer width="31%" justifyContent="space-between">
                <CurrencyInput
                  label="*Budget Per Person"
                  width="48%"
                  testId="average-price-per-person"
                  value={avgPricePerPerson}
                  decimals={2}
                  onChange={this.onChange('avgPricePerPerson')}
                />
                <Dropdown
                  label="*Budget Per Person Type"
                  width="48%"
                  testId="average-price-per-person-type"
                  value={avgPricePerPersonType}
                  onChange={(e) =>
                    this.onChangeGeneric(
                      'avgPricePerPersonType',
                      e.target.value,
                    )
                  }
                >
                  <option key={-1} value="">
                    Select
                  </option>
                  {AVERAGE_PRICE_PER_PERSON_TYPES.map((r) => (
                    <option key={r} value={r}>
                      {r}
                    </option>
                  ))}
                </Dropdown>
              </FlexContainer>
              <CurrencyInput
                label="*Estimated Monthly Value"
                width="31%"
                testId="average-price-per-person"
                value={estimatedMonthlyValue}
                decimals={2}
                onChange={this.onChange('estimatedMonthlyValue')}
              />
              <FlexContainer width="31%" />
            </FlexContainer>
            <YSpacing height="20px" />
            <FlexContainer width="100%" justifyContent="space-between">
              <FlexContainer width="31%" justifyContent="space-between">
                <Dropdown
                  label="*Client Type"
                  marginBottom="5px"
                  testId={'ordering-cadence'}
                  value={orderingCadence}
                  onChange={this.onSelectOrderingCadence}
                >
                  <option key={-1} value="">
                    Unknown
                  </option>
                  <option key={'adhoc'} value={'Ad-Hoc'}>
                    Ad-Hoc
                  </option>
                  <option key={'sow-recurring'} value={'SOW Recurring'}>
                    SOW Recurring
                  </option>
                  <option key={'non-sow-recurring'} value={'Non-SOW Recurring'}>
                    Non-SOW Recurring
                  </option>
                  <option key={'event'} value={'Event'}>
                    Event
                  </option>
                  <option key={'immaterial'} value={'Immaterial'}>
                    Immaterial
                  </option>
                </Dropdown>
              </FlexContainer>
              <FlexContainer width="31%" justifyContent="space-between">
                <Dropdown
                  label="*Ordering Cadence"
                  marginBottom="5px"
                  testId={'ordering-frequency'}
                  value={orderingFrequency}
                  onChange={this.onSelectOrderingFrequency}
                >
                  <option key={-1} value="">
                    No-cadence / No Pattern
                  </option>
                  <option key={'weekly'} value={'Weekly'}>
                    Weekly
                  </option>
                  <option key={'monthly'} value={'Monthly'}>
                    Monthly
                  </option>
                  <option key={'quarterly'} value={'Quarterly'}>
                    Quarterly
                  </option>
                  <option key={'seasonal'} value={'Seasonal'}>
                    Seasonal
                  </option>
                  <option key={'events'} value={'Events'}>
                    Events
                  </option>
                </Dropdown>
              </FlexContainer>
              <FlexContainer width="31%">
                {this.renderIncumbentDropDown()}
              </FlexContainer>
            </FlexContainer>
            <YSpacing height="20px" />
            <FlexContainer width="100%" justifyContent="space-between">
              <FlexContainer
                width="31%"
                justifyContent="start"
                alignItems="flex-start"
              >
                <AutocompleteInput
                  width="100%"
                  label="*Domain"
                  value={domain}
                  testId="domain"
                  loaderFunction={this.getDomainsFromContacts}
                  onSelect={this.onChangeDomain}
                  onInput={this.onChangeDomain}
                />
              </FlexContainer>
              {orderingCadence === 'SOW Recurring' && (
                <FlexContainer
                  width="31%"
                  flexDirection="column"
                  justifyContent="end"
                  alignItems="flex-end"
                >
                  <Label>SOW Effective Date</Label>
                  <DateInput
                    width="100%"
                    date={sowEffectiveDate}
                    onChange={(sowEffectiveDate) =>
                      this.setState({ sowEffectiveDate })
                    }
                    clearDate={() => this.setState({ sowEffectiveDate: null })}
                  />
                </FlexContainer>
              )}
            </FlexContainer>
            <YSpacing height="20px" />
            <FlexContainer width="100%" justifyContent="space-between">
              <FlexContainer
                width="31%"
                justifyContent="space-between"
                flexDirection="column"
              >
                <AutocompleteInput
                  label="*Account Executive"
                  width="100%"
                  loaderFunction={loadAccountExecutives()}
                  value={
                    clientHunter &&
                    `${clientHunter.firstName} ${clientHunter.lastName}`
                  }
                  onSelect={this.onChangeClientHunter}
                />
              </FlexContainer>
              <FlexContainer
                width="31%"
                justifyContent="space-between"
                flexDirection="column"
              >
                <AutocompleteInput
                  label="*Client Success Manager"
                  width="100%"
                  loaderFunction={loadAccountExecutives()}
                  value={
                    clientChampion &&
                    `${clientChampion.firstName} ${clientChampion.lastName}`
                  }
                  onSelect={this.onChangeClientChampion}
                />
              </FlexContainer>
              <FlexContainer
                width="31%"
                justifyContent="space-between"
                flexDirection="column"
              >
                <AutocompleteInput
                  label="SDR"
                  width="100%"
                  loaderFunction={loadAccountExecutives()}
                  value={
                    clientSDR && `${clientSDR.firstName} ${clientSDR.lastName}`
                  }
                  onSelect={this.onChangeClientSDR}
                />
              </FlexContainer>
            </FlexContainer>
            <YSpacing height="20px" />
            <FlexContainer alignItems="center">
              <h2 className="sidebar-body-heading">
                Customized Settings for Catering Orders
              </h2>
              <XSpacing width="20px" />
              {isCollapseCustomizedSection ? (
                <IoCaretForwardCircle
                  color={colors.orange}
                  size={25}
                  onClick={() =>
                    this.setState({
                      isCollapseCustomizedSection: !isCollapseCustomizedSection,
                    })
                  }
                />
              ) : (
                <IoCaretDownCircle
                  color={colors.orange}
                  size={25}
                  onClick={() =>
                    this.setState({
                      isCollapseCustomizedSection: !isCollapseCustomizedSection,
                    })
                  }
                />
              )}
            </FlexContainer>
            {!isCollapseCustomizedSection && (
              <FlexContainer flexDirection="column">
                <DividerLine margin="20px 0" />
                <CustomizedSettingsSection
                  gratuityType={gratuityType}
                  gratuity={gratuity}
                  needStaffAndServe={needStaffAndServe}
                  cateringStaffCount={cateringStaffCount}
                  cateringStaffHours={cateringStaffHours}
                  cateringStaffRate={cateringStaffRate}
                  cateringStaffFee={cateringStaffFee}
                  deliveryAndServiceFeePercentWeekend={
                    deliveryAndServiceFeePercentWeekend
                  }
                  deliveryAndServiceFeePercentWeekday={
                    deliveryAndServiceFeePercentWeekday
                  }
                  deliveryAndServiceFeePercentHoliday={
                    deliveryAndServiceFeePercentHoliday
                  }
                  deliveryAndServiceFeeCapWeekend={
                    deliveryAndServiceFeeCapWeekend
                  }
                  deliveryAndServiceFeeCapWeekday={
                    deliveryAndServiceFeeCapWeekday
                  }
                  deliveryAndServiceFeeCapHoliday={
                    deliveryAndServiceFeeCapHoliday
                  }
                  setUpCompleteByTime={setUpCompleteByTime}
                  doNotArriveBeforeTime={doNotArriveBeforeTime}
                  lastUpdatedByGratuityAmount={lastUpdatedByGratuityAmount}
                  lastUpdatedByStaffingFee={lastUpdatedByStaffingFee}
                  lastUpdatedByDeliveryAndServiceWeekendFee={
                    lastUpdatedByDeliveryAndServiceWeekendFee
                  }
                  lastUpdatedByDeliveryAndServiceWeekdayFee={
                    lastUpdatedByDeliveryAndServiceWeekdayFee
                  }
                  lastUpdatedByDeliveryAndServiceHolidayFee={
                    lastUpdatedByDeliveryAndServiceHolidayFee
                  }
                  lastUpdatedBySetUpCompleteByTime={
                    lastUpdatedBySetUpCompleteByTime
                  }
                  lastUpdatedByDoNotArriveBeforeTime={
                    lastUpdatedByDoNotArriveBeforeTime
                  }
                  lastUpdatedByClientNotes={lastUpdatedByClientNotes}
                  lastUpdatedByCuisinePreferences={
                    lastUpdatedByCuisinePreferences
                  }
                  lastUpdatedByClientSuccessMetrics={
                    lastUpdatedByClientSuccessMetrics
                  }
                  lastUpdatedAtGratuityAmount={lastUpdatedAtGratuityAmount}
                  lastUpdatedAtStaffingFee={lastUpdatedAtStaffingFee}
                  lastUpdatedAtDeliveryAndServiceWeekendFee={
                    lastUpdatedAtDeliveryAndServiceWeekendFee
                  }
                  lastUpdatedAtDeliveryAndServiceWeekdayFee={
                    lastUpdatedAtDeliveryAndServiceWeekdayFee
                  }
                  lastUpdatedAtDeliveryAndServiceHolidayFee={
                    lastUpdatedAtDeliveryAndServiceHolidayFee
                  }
                  lastUpdatedAtSetUpCompleteByTime={
                    lastUpdatedAtSetUpCompleteByTime
                  }
                  lastUpdatedAtDoNotArriveBeforeTime={
                    lastUpdatedAtDoNotArriveBeforeTime
                  }
                  lastUpdatedAtClientNotes={lastUpdatedAtClientNotes}
                  lastUpdatedAtCuisinePreferences={
                    lastUpdatedAtCuisinePreferences
                  }
                  lastUpdatedAtClientSuccessMetrics={
                    lastUpdatedAtClientSuccessMetrics
                  }
                  onChange={this.onChangeGeneric}
                  onChangeStaffingFeeFields={this.onChangeStaffingFeeFields}
                  onChangeCheckbox={this.onChangeCheckbox}
                  renderLastUpdated={this.renderLastUpdated}
                />
              </FlexContainer>
            )}

            <DividerLine margin="20px 0" />
            <YSpacing height="20px" />
            <FlexContainer
              width="80%"
              justifyContent="space-between"
              flexDirection="column"
            >
              <Label> *Service Days </Label>
              <YSpacing height="10px" />
              <FlexContainer justifyContent="space-between">
                {daysOfWeek.map((day, i) => (
                  <Checkbox
                    key={i}
                    label={day}
                    value={day}
                    checked={serviceDays && serviceDays.includes(day)}
                    onChange={() => this.onChangeServiceDays(day)}
                  />
                ))}
              </FlexContainer>
            </FlexContainer>
            <YSpacing height="40px" />
            <FlexContainer width="100%">
              <Checkbox
                label="Is Tax Exempt?"
                value="isTaxExempt"
                checked={isTaxExempt}
                onChange={this.onChangeCheckbox}
              />
              <Checkbox
                label="Call On Arrival?"
                value="callOnArrival"
                checked={callOnArrival}
                onChange={this.onChangeCheckbox}
              />
              <Checkbox
                label="Carbon Neutral?"
                value="carbonNeutral"
                checked={carbonNeutral}
                onChange={this.onCheckCarbonNeutral}
              />
            </FlexContainer>
            <YSpacing height="20px" />
            <FlexContainer width="100%">
              <Checkbox
                label="Require Client Reporting Programs?"
                value="requireClientReportingPrograms"
                checked={requireClientReportingPrograms}
                onChange={this.onChangeCheckbox}
              />
            </FlexContainer>
            <YSpacing height="20px" />
            <FlexContainer>
              <Checkbox
                label="Track Item Consumption?"
                value="trackConsumption"
                checked={trackConsumption}
                onChange={this.onChangeCheckbox}
              />
            </FlexContainer>
            <YSpacing height="20px" />
            <FlexContainer>
              <Checkbox
                label="Show Menu Item Description on Table Tent Cards"
                value="showFoodLabelDescription"
                checked={showFoodLabelDescription}
                onChange={this.onChangeCheckbox}
              />
            </FlexContainer>
            <YSpacing height="20px" />
            <FlexContainer width="100%">
              <FlexContainer
                width="40%"
                justifyContent="space-between"
                flexDirection="column"
              >
                <Label> *Needs Utensils Napkins &amp; Plates? </Label>
                <YSpacing height="5px" />
                <RadioButton
                  testId="needs-napkins-and-plates"
                  name="needsUtensils"
                  value="Yes"
                  checked={needsUtensils}
                  onChange={this.onChangeRadioButton}
                />
                <YSpacing height="5px" />
                <RadioButton
                  testId="needs-napkins-and-plates"
                  name="needsUtensils"
                  value="No"
                  checked={!needsUtensils}
                  onChange={this.onChangeRadioButton}
                />
              </FlexContainer>
              <FlexContainer
                width="40%"
                justifyContent="space-between"
                flexDirection="column"
              >
                <Label> *Needs Serving Utensils? </Label>
                <YSpacing height="5px" />
                <RadioButton
                  testId="needs-serving-utensils"
                  name="needsServingUtensils"
                  value="Yes"
                  checked={needsServingUtensils}
                  onChange={this.onChangeRadioButton}
                />
                <YSpacing height="5px" />
                <RadioButton
                  testId="needs-serving-utensils"
                  name="needsServingUtensils"
                  value="No"
                  checked={!needsServingUtensils}
                  onChange={this.onChangeRadioButton}
                />
              </FlexContainer>
            </FlexContainer>
            <YSpacing height="20px" />
            {carbonNeutral && (
              <FlexContainer
                width="100%"
                justifyContent="flex-start"
                flexDirection="column"
              >
                <CurrencyInput
                  label="Carbon Neutral Contribution (%)"
                  value={carbonNeutralContribution}
                  onChange={(v) => this.onChangeCarbonNeutralContrib(v)}
                  width="31%"
                  decimals={2}
                  testId="carbon-neutral-contribution"
                />
                <FlexContainer>
                  {[50, 100].map((percent) =>
                    this.renderCarbonContribButton(percent),
                  )}
                </FlexContainer>
              </FlexContainer>
            )}
            {carbonNeutral && <YSpacing height="20px" />}
            {isTaxExempt && (
              <FlexContainer width="100%" justifyContent="flex-start">
                {id ? (
                  <>
                    <FlexContainer
                      width="33%"
                      justifyContent="space-between"
                      flexDirection="column"
                    >
                      <Label> Tax Exemption Document </Label>
                      {taxExemptionDocument ? (
                        <FlexContainer flexDirection="row">
                          <a
                            className="order-link mr-2"
                            href={taxExemptionDocument}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Open Document
                          </a>
                          <IconButton
                            id="delete-tax-exemption"
                            onClick={() =>
                              this.setState({ taxExemptionDocument: '' })
                            }
                          >
                            <BiTrash />
                          </IconButton>
                        </FlexContainer>
                      ) : (
                        <Label> None </Label>
                      )}
                    </FlexContainer>
                    <FlexContainer
                      width="33%"
                      justifyContent="space-between"
                      flexDirection="column"
                    >
                      <Label>
                        {' '}
                        {taxExemptionDocument ? 'Replace' : 'Upload '} Tax
                        Exemption Document (PDF){' '}
                      </Label>
                      <input
                        id="uploadTaxDocId"
                        type="file"
                        accept=".pdf"
                        onChange={this.onChooseFile('taxExemptionDocument')}
                      />
                      {this.renderDocumentSaveButton('taxExemptionDocument')}
                    </FlexContainer>
                  </>
                ) : (
                  <strong>
                    Please save client before uploading tax exempt document.
                  </strong>
                )}
              </FlexContainer>
            )}
            <YSpacing height="20px" />
            {id && (
              <FlexContainer width="100%" justifyContent="flex-start">
                <FlexContainer
                  width="33%"
                  justifyContent="space-between"
                  flexDirection="column"
                >
                  <Label> Statement of Work </Label>
                  {statementOfWork ? (
                    <FlexContainer flexDirection="row">
                      <a
                        className="order-link mr-2"
                        href={statementOfWork}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Open Document
                      </a>
                      <IconButton
                        id="delete-sow"
                        onClick={() => this.setState({ statementOfWork: '' })}
                      >
                        <BiTrash />
                      </IconButton>
                    </FlexContainer>
                  ) : (
                    <Label> None </Label>
                  )}
                </FlexContainer>
                <FlexContainer
                  width="33%"
                  justifyContent="space-between"
                  flexDirection="column"
                >
                  <Label>
                    {' '}
                    {statementOfWork ? 'Replace' : 'Upload '} Statement of Work
                    (PDF){' '}
                  </Label>
                  <input
                    id="uploadStatementId"
                    type="file"
                    accept=".pdf"
                    onChange={this.onChooseFile('statementOfWork')}
                  />
                  {this.renderDocumentSaveButton('statementOfWork')}
                </FlexContainer>
              </FlexContainer>
            )}
            <YSpacing height="20px" />
            {id && (
              <FlexContainer width="100%" justifyContent="flex-start">
                <FlexContainer
                  width="33%"
                  justifyContent="space-between"
                  flexDirection="column"
                >
                  <Label> Partnership Logo </Label>
                  {logoUrl ? (
                    <FlexContainer flexDirection="row">
                      <a
                        className="order-link mr-2"
                        href={logoUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Open Logo
                      </a>
                      <IconButton
                        id="delete-sow"
                        onClick={() => this.setState({ logoUrl: '' })}
                      >
                        <BiTrash />
                      </IconButton>
                    </FlexContainer>
                  ) : (
                    <Label> None </Label>
                  )}
                </FlexContainer>
                <FlexContainer
                  width="33%"
                  justifyContent="space-between"
                  flexDirection="column"
                >
                  <Label>
                    {' '}
                    {statementOfWork ? 'Replace' : 'Upload '} Partnership Logo
                    Image{' '}
                  </Label>
                  <Label> (Pixel Dimensions: 719 x 53) </Label>
                  <input
                    id="uploadLogoImg"
                    type="file"
                    // accept=".pdf"
                    onChange={this.onChooseLogo}
                  />
                  {logoFile && (
                    <LinkText
                      label="Upload"
                      onClick={this.onSaveLogo}
                      color={colors.blue400}
                    />
                  )}
                </FlexContainer>
              </FlexContainer>
            )}
            <YSpacing height="10px" />
            <Checkbox
              label="Hide Client on Client/Chef Map"
              value="hideFromMapView"
              checked={hideFromMapView}
              onChange={this.onChangeCheckbox}
            />
            <YSpacing height="20px" />
            <FlexContainer width="100%" justifyContent="flex-start">
              <FlexContainer
                width="33%"
                justifyContent="space-between"
                flexDirection="column"
              >
                <Label> *First Order Date </Label>
                <AuthorizedInteractable
                  roles={['master admin', 'finance', 'sales rep', 'sales lead']}
                >
                  <DateInput
                    width="75%"
                    date={firstOrderDate}
                    onChange={(firstOrderDate) =>
                      this.setState({ firstOrderDate })
                    }
                    clearDate={() => this.setState({ firstOrderDate: null })}
                  />
                </AuthorizedInteractable>
              </FlexContainer>
              <FlexContainer
                width="33%"
                justifyContent="space-between"
                flexDirection="column"
              >
                <AuthorizedInteractable
                  roles={['master admin', 'finance', 'sales rep', 'sales lead']}
                >
                  <Dropdown
                    label="*Date Type"
                    width="75%"
                    testId="first-order-date-type"
                    value={firstOrderDateType}
                    onChange={(e) =>
                      this.onChangeGeneric('firstOrderDateType', e.target.value)
                    }
                  >
                    <option key={-1} value="">
                      Select
                    </option>
                    {FIRST_ORDER_DATE_TYPES.map((r) => (
                      <option key={r} value={r}>
                        {r}
                      </option>
                    ))}
                  </Dropdown>
                </AuthorizedInteractable>
              </FlexContainer>
            </FlexContainer>
            <YSpacing height="20px" />
            <FlexContainer flexDirection="row" alignItems="center">
              <Label fontSize="13px"> *Client Notes </Label>
              <XSpacing width="10px" />
              <TooltipModal
                unicode="&#9432;"
                width="400px"
                information="Type and Frequency of Service - e.g. Recurring bagels Tuesday, lunch and learn weekly and monthly corporate HH/events"
              />
              <XSpacing width="10px" />
              {this.renderLastUpdated(
                lastUpdatedByClientNotes,
                lastUpdatedAtClientNotes,
              )}
            </FlexContainer>
            <YSpacing height="20px" />
            <TextArea
              width={'100%'}
              height="100px"
              marginBottom={'0px'}
              value={clientNotes}
              onChange={(e) =>
                this.onChangeGeneric('clientNotes', e.target.value)
              }
            />
            <YSpacing height="20px" />
            <FlexContainer flexDirection="row" alignItems="center">
              <Label fontSize="13px"> *Cuisine Preferences </Label>
              <XSpacing width="10px" />
              <TooltipModal
                unicode="&#9432;"
                width="400px"
                information="Cuisine, dietary restrictions and/or preferences"
              />
              <XSpacing width="10px" />
              {this.renderLastUpdated(
                lastUpdatedByCuisinePreferences,
                lastUpdatedAtCuisinePreferences,
              )}
            </FlexContainer>
            <TextArea
              width={'100%'}
              marginBottom={'0px'}
              value={cuisinePreferences}
              height="100px"
              onChange={(e) =>
                this.onChangeGeneric('cuisinePreferences', e.target.value)
              }
            />
            <YSpacing height="20px" />
            <FlexContainer flexDirection="row" alignItems="center">
              <Label fontSize="13px"> *Client Success Metrics </Label>
              <XSpacing width="10px" />
              <TooltipModal
                unicode="&#9432;"
                width="400px"
                information="Menu variety, timeliness, presentation, quality, etc."
              />
              <XSpacing width="10px" />
              {this.renderLastUpdated(
                lastUpdatedByClientSuccessMetrics,
                lastUpdatedAtClientSuccessMetrics,
              )}
            </FlexContainer>
            <TextArea
              width={'100%'}
              height="100px"
              marginBottom={'0px'}
              value={clientSuccessMetrics}
              onChange={(e) =>
                this.onChangeGeneric('clientSuccessMetrics', e.target.value)
              }
            />

            <DividerLine margin="20px 0" />
            {this.renderAddressList()}
            {this.renderContactList()}
            <FlexContainer width="100%" justifyContent="space-between">
              <FlexContainer
                width="45%"
                justifyContent="space-between"
                flexDirection="column"
              >
                {!id && (
                  <div>
                    <Label fontSize="16px"> Payment Method </Label>
                    <YSpacing height="10px" />
                  </div>
                )}
                {id &&
                  this.renderResourceDropdown(
                    'paymentMethod',
                    'Payment Method',
                    'select-existing-payment-method',
                  )}
                {this.renderResourceDeleted(
                  'paymentMethod',
                  'select-existing-payment-method',
                )}
                <YSpacing height="3px" />
                <IconButton
                  id="add-another-payment-method"
                  onClick={this.onAddNewResource('paymentMethod')}
                >
                  <RiWallet3Line /> Add Another Payment Method
                </IconButton>
                <YSpacing height="10px" />
                {this.renderResourceInputs('paymentMethod')}
              </FlexContainer>
              {addresses && addresses.length > 0 && id && (
                <Dropdown
                  label="Default Billing Address"
                  width="45%"
                  testId="default-billing-address"
                  value={defaultBillingAddress && defaultBillingAddress.id}
                  onChange={this.onSelectResource('defaultBillingAddress')}
                >
                  {defaultAddressList &&
                    defaultAddressList.map((r) => (
                      <option key={r.id} value={r.id}>
                        {r.fullAddress || r.name || r.shortName}
                      </option>
                    ))}
                </Dropdown>
              )}
            </FlexContainer>
            <YSpacing height="20px" />
            <FlexContainer width="100%" justifyContent="space-between">
              {id && (
                <FlexContainer
                  width="45%"
                  justifyContent="space-between"
                  flexDirection="column"
                >
                  {this.renderResourceDropdown(
                    'referredBy',
                    'Referral Partner',
                    'select-referral-partner',
                  )}
                  {this.renderResourceDeleted(
                    'referredBy',
                    'select-referral-partner',
                  )}
                </FlexContainer>
              )}
              {id && (
                <FlexContainer flexDirection="column" width="45%">
                  {this.renderResourceDropdown(
                    'mktplaceUser',
                    'Marketplace User',
                    'select-existing-mktplace-user',
                  )}
                  {this.renderResourceDeleted(
                    'mktplaceUser',
                    'select-existing-mktplace-user',
                  )}
                  <YSpacing height="3px" />
                  <IconButton
                    id="add-existing-contact"
                    onClick={this.onAddExistingResource('mktplaceUser')}
                  >
                    <BsPerson /> Add Existing Marketplace User
                  </IconButton>
                  <YSpacing height="10px" />
                  {this.renderResourceInputs('mktplaceUser')}
                </FlexContainer>
              )}
            </FlexContainer>

            <DividerLine margin="20px 0" />
            {this.renderNetPaymentSettingsInput()}
            <DividerLine margin="20px 0" />
            {this.renderAutoBatchInvSettingsInput()}
            <DividerLine margin="20px 0" />
            {this.renderCateringReminderSettingsInput()}
            <DividerLine margin="20px 0" />

            <YSpacing height="20px" />
            <FlexContainer alignItems="center" justifyContent="flex-end">
              <TechHelpForm margin="0 30px 0 0" />
              <LinkText
                label="Cancel"
                onClick={this.onHide}
                color={colors.gray400}
              />
              <XSpacing width="30px" />
              {id && <XSpacing width="30px" />}
              <AuthorizedInteractable
                roles={[
                  'master admin',
                  'sales rep',
                  'sales lead',
                  'captain lead',
                ]}
              >
                <Button label="Save" onClick={this.onSave} />
              </AuthorizedInteractable>
            </FlexContainer>
          </div>
        )}
      </Modal>
    )
  }
}

const Info = styled.div`
  padding-left: 5px;
  z-index: 100;
  position: relative;
  display: inline-block;
  &:hover .tooltip {
    opacity: 1;
  }
`
const PopOver = styled.div`
  z-index: 1;
  opacity: 0;
  pointer-events: none;
  white-space: nowrap;
  position: absolute;
  background: #fff;
  color: #fff;
  padding: 7px;
  bottom: 30px;
  border: 1px solid gray;
  width: 500px;
  right: 0;
`

EditAccountModal.propTypes = {
  account: PropTypes.object,
  accountContacts: PropTypes.array,
  dinerProfiles: PropTypes.array,
  errors: PropTypes.object,
  host: PropTypes.string,
  hubspotCompany: PropTypes.object,
  hubspotCompanies: PropTypes.array,
  hubspotContact: PropTypes.object,
  hubspotContacts: PropTypes.array,
  parentAccount: PropTypes.object,
  referredBy: PropTypes.object,
  referralPartners: PropTypes.array,
  show: PropTypes.bool,
  currentUser: PropTypes.object,

  flashWarning: PropTypes.func,
  flashError: PropTypes.func,
  clearErrors: PropTypes.func,
  close: PropTypes.func,
  delayedUpdateAccount: PropTypes.func,
  deleteAccount: PropTypes.func,
  deleteAccountAddress: PropTypes.func,
  deleteAccountContact: PropTypes.func,
  getAutoInvoiceBillingAddress: PropTypes.func,
  loadAccountContacts: PropTypes.func,
  loadAccountOptions: PropTypes.func,
  loadAccountMarketplaceUsers: PropTypes.func,
  loadHubspotCompany: PropTypes.func,
  loadHubspotCompanyById: PropTypes.func,
  loadHubspotCompanies: PropTypes.func,
  loadHubspotContact: PropTypes.func,
  loadHubspotContacts: PropTypes.func,
  loadAccounts: PropTypes.func,
  loadMktplaceUsers: PropTypes.func,
  loadReferralPartners: PropTypes.func,
  markDefaultAccountAddress: PropTypes.func,
  markDefaultAccountContact: PropTypes.func,
  pContactToAccountingContact: PropTypes.func,
  pRequestUpdateAccount: PropTypes.func,
  saveAccount: PropTypes.func,
  savePaymentMethod: PropTypes.func,
  searchClientSettings: PropTypes.func,
  updateHubspotCompanyRef: PropTypes.func,
  updateHubspotContactRef: PropTypes.func,
  createAccountDocument: PropTypes.func,
  loadAccountExecutives: PropTypes.func,
  updateAccountContact: PropTypes.func,
  updateAccountAddress: PropTypes.func,
  updateClientSettingsClient: PropTypes.func,
  updatePaymentMethod: PropTypes.func,
  uploadAccountLogoImage: PropTypes.func,
  validateAddress: PropTypes.func,
  validateClientAutoInvBatchable: PropTypes.func,
}

export default EditAccountModal
