import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment-timezone'
import { Carousel } from 'react-bootstrap'
import { FaCheckCircle } from 'react-icons/fa'
import { AuthorizedInteractable } from '@containers/common/auth'
import {
  Checkbox,
  CurrencyInput,
  QuantityInput,
  RadioButton,
  FileUpload,
  AutocompleteInput,
} from '@components/common/form'
import Modal from '@components/common/modal/Modal'
import YSpacing from '@components/common/YSpacing'
import XSpacing from '@components/common/XSpacing'
import DividerLine from '@components/common/DividerLine'
import FlexContainer from '@components/common/FlexContainer'
import Dropdown from '@components/common/form/Dropdown'
import LinkText from '@components/common/form/LinkText'
import Button from '@components/common/form/Button'
import Input from '@components/common/form/Input'
import TextArea from '@components/common/form/TextArea'
import { MenuItemVerifications } from '@components/chef/edit'
import {
  Label,
  OrderFormSectionTitle,
  ChildItem,
  ServingWeightForm,
} from '@res/styledComponents/index'
import { colors } from '../../../../constants'
import removedImage from '@res/images/image-removed-320x240.png'
import { TooltipModal } from '../../common/modal'
import { MarketTypes, MaxImageSizeKB } from '@constants'
import { AllMpCalculationTypes, ServingWeights } from '@constants'

const menuItemInitialState = {
  childMenuItems: [],
  images: [],
  chefPrice: 0.0,
  countPerShelf: 6,
  marketPrice: 0.0,
  marketPriceMarkupPercentage: 0.4,
  retailPrice: 0.0,
  showMarkupPercentagePopup: false,
  dailyOrderLimit: null,
  menuItemImages: [],
  minQty: 8,
  subMenuItemAllCategories: [],
  subMenuItems: [],
  creatorId: '',
  pricePerPersonCalc: 'PerPerson',
  selectedChef: undefined,
  selectedAddOnItem: undefined,
  uploadedNewImage: false,
  chefMap: {},

  allNonPlatterPackagings: [],
  allPlatterPackagings: [],
  allDietaryPreferences: [],
  allMealTypes: [],
  allServingUtensils: [],
  allMarketTypes: [],
  allGroupOrderMealTypes: [],
}

const imageCopy = `For Group Order menu item images, please use .jpg files no larger than ${MaxImageSizeKB}kb and ensure that the image is approximately square and has the subject in the center.`

const ADD_ON_CHEF_FILTERS = {
  status: 'active',
}

const ADD_ON_ITEM_FILTERS = {
  parent_items: true,
  market_type: 'Group Order',
  go_add_ons: true,
}

const ImageSourceTypes = {
  AI: 'AI_Image',
  Actual: 'Actual_Image',
}

class EditMenuItemModal extends Component {
  state = { ...menuItemInitialState }

  componentWillMount() {
    const { menuItem, clearErrors, user } = this.props
    const packagingId = menuItem.packaging && menuItem.packaging.id
    const servingUtensilId =
      menuItem.servingUtensil && menuItem.servingUtensil.id
    const creatorId = user && user.id
    const newState = { ...menuItem, packagingId, servingUtensilId, creatorId }
    const { images, imageVerified } = menuItem
    // reset image verified tag if no images on item
    if (images?.length === 0 && imageVerified) {
      newState.imageVerified = false
    }
    this.setState(newState)
    clearErrors()
    this.initMenuItem()
  }

  componentWillReceiveProps(nextProps) {
    const { menuItem } = nextProps
    if (this.props.menuItem !== nextProps.menuItem) {
      const newState = { ...menuItem }

      this.state.allDietaryPreferences.forEach((t) => {
        newState[this.getDietaryPrefKey(t)] = false
      })
      ;(menuItem.tagsDietaryPreference || []).forEach((t) => {
        newState[this.getDietaryPrefKey(t)] = true
      })
      this.setState(newState)
    }
  }

  initMenuItem = async () => {
    const { menuItem } = this.props
    const settings = await this.props.loadMenuItemSettings()
    const {
      allNonPlatterPackagings,
      allPlatterPackagings,
      allDietaryPreferences,
      allMealTypes,
      allServingUtensils,
      allMarketTypes,
    } = settings

    const allGroupOrderMealTypes = settings.allGroupOrderMealTypes || []

    const servingUtensils = allServingUtensils.map((u) => {
      return { value: u.id, text: u.name }
    })

    servingUtensils.unshift({})

    const newState = {
      allNonPlatterPackagings,
      allPlatterPackagings,
      allDietaryPreferences,
      allMealTypes,
      allServingUtensils: servingUtensils,
      allMarketTypes,
      allGroupOrderMealTypes,
    }
    allDietaryPreferences.forEach((t) => {
      newState[this.getDietaryPrefKey(t)] = false
    })
    menuItem.tagsDietaryPreference.forEach((t) => {
      newState[this.getDietaryPrefKey(t)] = true
    })
    allGroupOrderMealTypes.forEach((t) => {
      newState[this.getGOMealTypeKey(t)] = false
    })
    menuItem.tagsGroupOrderMealType.forEach((t) => {
      newState[this.getGOMealTypeKey(t)] = true
    })
    this.setState(newState)
  }

  getDietaryPrefKey = (tag) => {
    return `dietaryTag${tag}`
  }

  getGOMealTypeKey = (tag) => {
    return `goMealType${tag}`
  }

  getLastVerification = (verificationData) => {
    const lastVerification = verificationData.reduce(
      (lastVerification, current) => {
        if (
          current.date &&
          (!lastVerification.date ||
            Moment(current.date).isAfter(Moment(lastVerification.date)))
        ) {
          return current
        }

        return lastVerification
      },
      { date: null, verifier: null },
    )

    return lastVerification
  }

  getChildItemValidations = (childItem) => {
    const { pToMenuItemVerifications } = this.props
    const verifications = pToMenuItemVerifications(childItem)
    const verificationsCompleted = verifications.filter((v) => v).length
    const applicableVerifications = verifications.length
    const allVerificationsCompleted =
      verificationsCompleted === applicableVerifications

    return {
      completed: allVerificationsCompleted,
      allergensAndDescriptionVerified:
        childItem.allergensVerified && childItem.descriptionVerified,
      message: `${verificationsCompleted}/${applicableVerifications} Verifications Complete`,
    }
  }

  /* Events */

  onAddImage = (image) => {
    const { displayAlert } = this.props
    const { marketType } = this.state
    const { size } = image

    if (size / 1024 > MaxImageSizeKB) {
      displayAlert({
        title: 'Image File Too Large',
        message:
          marketType === MarketTypes.GROUP_ORDER
            ? imageCopy
            : `Images must be less than ${MaxImageSizeKB}kb in size.`,
        options: {
          isLink: true,
          link: 'https://imresizer.com/resize-image-size-in-kb',
          message: 'Please try using this link to resize the image:',
        },
      })

      return
    }

    // Check for mime type of image/png image/jpeg
    const acceptedTypes = ['image/jpeg', 'image/png']
    if (!acceptedTypes.includes(image.type)) {
      displayAlert({
        title: 'Wrong image type',
        message: 'Image must be of type JPEG or PNG',
      })

      return
    }

    let { images } = this.state
    images = [...images, image]
    this.setState({ images, uploadedNewImage: true })
  }

  onChange = (key) => (e) => {
    const { value } = e.target
    this.setState({ [key]: value })
  }

  onChangeCheckbox = (e) => {
    const { checked, value: key } = e.target
    this.setState({ [key]: checked })
  }

  onChangeMarketplaceAddOn = () => (e) => {
    const { checked } = e.target
    const { id } = this.state
    let { marketplaceAddOn } = this.state
    if (checked) {
      marketplaceAddOn = { ...marketplaceAddOn, menuItemId: id, destroy: false }
    } else {
      marketplaceAddOn = { ...marketplaceAddOn, destroy: true }
    }

    this.setState({ marketplaceAddOn })
  }

  onChangeDietaryPref = (e) => {
    const { checked, value: key } = e.target
    const newState = { ...this.state, [this.getDietaryPrefKey(key)]: checked }

    const tags = []
    newState.allDietaryPreferences &&
      newState.allDietaryPreferences.forEach((p) => {
        if (newState[`dietaryTag${p}`] === true) {
          tags.push(p)
        }
      })
    newState.tagsDietaryPreference = tags
    this.setState(newState)
  }

  onChangeGroupOrderMealType = (e) => {
    const { checked, value: key } = e.target
    // Only allow a single GO meal type to be set to true at a time
    const allMealTypes = this.state.allGroupOrderMealTypes || []
    const nextState = {}
    allMealTypes.forEach((mealType) => {
      const stateKey = this.getGOMealTypeKey(mealType)
      nextState[stateKey] = false
    })
    const mealTypeKey = this.getGOMealTypeKey(key)
    nextState[mealTypeKey] = checked
    const tags = []
    allMealTypes &&
      allMealTypes.forEach((t) => {
        if (nextState[`goMealType${t}`] === true) {
          tags.push(t)
        }
      })
    nextState.tagsGroupOrderMealType = tags
    this.setState(nextState)
  }

  onChangeMenuItem = (key, value) => {
    this.setState({ [key]: value })
  }

  onChangeRadioButton = (e) => {
    const { value: key, name } = e.target
    this.setState({ [name]: key })
  }

  onChangeMarkupPercentage = (e) => {
    const { value } = e.target
    this.setState({ marketPriceMarkupPercentage: Number(value) })
  }

  onMarketTypeChange = (e) => {
    const { value: key, name } = e.target
    const nextState = { [name]: key }
    if (key !== 'Group Order') {
      // Set GO tags to be false if office type
      const allMealTypes = this.state.allGroupOrderMealTypes || []
      allMealTypes.forEach((mealType) => {
        const stateKey = this.getGOMealTypeKey(mealType)
        nextState[stateKey] = false
      })
    }
    this.setState(nextState)
  }

  onSaveMarketPriceMarkupPercentage = () => {
    const { calculateMarketPrice } = this.props
    const { chefPrice, marketPriceMarkupPercentage } = this.state
    this.onChangeMenuItem(
      'marketPrice',
      calculateMarketPrice(Number(chefPrice), marketPriceMarkupPercentage),
    )
    this.setState({ showMarkupPercentagePopup: false })
  }

  onCancelMarketPriceMarkupPercentage = () => {
    this.setState({ showMarkupPercentagePopup: false })
  }

  onCreateChildMenuItem = () => {
    const { id, name, mealType } = this.state
    this.props.newChildMenuItem(
      {
        parentMenuItemId: id,
        parentMenuItemName: name,
        mealType,
      },
      this.state,
    )
  }

  onDelete = () => {
    const { close, deleteMenuItem } = this.props
    const { chefId, id, name } = this.state
    deleteMenuItem({ id, chefId, name })
    close()
  }

  onDeleteImage = (index) => {
    const { images } = this.state
    images[index].destroy = !images[index].destroy
    // reset image verified to false if image is removed
    this.setState({ images: [...images], imageVerified: false })
  }

  onChangeImageSourceType = (sourceType) => {
    const { images } = this.state
    const checked = images.length > 0 && images[0].sourceType == sourceType
    const newImages = [...images]
    if (checked) {
      newImages[0].sourceType = null
    } else {
      newImages[0].sourceType = sourceType
    }

    this.setState({ images: newImages })
  }

  onRestoreItem = async () => {
    const { close, restoreMenuItem } = this.props
    const { chefId, id, name } = this.state
    await restoreMenuItem({ id, chefId, name })
    close()
  }

  onHide = () => {
    this.props.close()
  }

  onLoadMenuItem = (menuItemId) => () => {
    const { editChildMenuItem, isCopy, displayAlert } = this.props

    if (isCopy) {
      displayAlert({
        message: "Can't edit child items on a copy",
        title: 'Copy Item Info',
      })

      return
    }

    editChildMenuItem(menuItemId)
  }

  onSave = async () => {
    if (!this.validate()) {
      return
    }
    const { saveMenuItem, isCopy, loadMenuItems } = this.props
    const success = await saveMenuItem(this.state, isCopy)
    if (success) {
      loadMenuItems && loadMenuItems()
    }
  }

  validate = () => {
    const {
      imageSourceType,
      images,
      imageVerified,
      packagingId,
      tagsDietaryPreference = [],
      marketType,
      servingsPerPkg,
      uploadedNewImage,
    } = this.state
    const errors = []

    if (
      images.filter((i) => !i.destroy).length > 0 &&
      !imageSourceType &&
      uploadedNewImage
    ) {
      errors.push('Must select an image tag when saving an item with an image.')
    }
    if (images.filter((i) => !i.destroy).length === 0 && imageSourceType) {
      errors.push(
        'There are no images associated with this menu item, please remove the image tag.',
      )
    }
    if (images.filter((i) => !i.destroy).length === 0 && imageVerified) {
      errors.push(
        'Image verified checkbox cannot be selected when there are no images for the menu item.',
      )
    }
    if (tagsDietaryPreference.length == 0) {
      errors.push('Must have at least one dietary tag.')
    }
    if (packagingId && !servingsPerPkg) {
      errors.push(
        'Must have a non-zero servings per package if packaging is present.',
      )
    }
    if (!!servingsPerPkg && servingsPerPkg < 1.0) {
      errors.push('Cannot have a servings per package less than 1.0.')
    }

    const prefContras = {
      vegetarian: {
        exclude: ['contains pork', 'contains shellfish', 'contains fish'],
        error: 'Dietary tags cannot contain vegetarian and meat products.',
      },
      vegan: {
        exclude: [
          'contains pork',
          'contains shellfish',
          'contains dairy',
          'contains eggs',
          'contains fish',
        ],
        error:
          'Dietary tags cannot contain vegetarian and meat, dairy, or eggs.',
      },
      halal: {
        exclude: ['contains pork'],
        error: 'Dietary tags cannot contain pork and halal.',
      },
      'prepared without gluten': {
        exclude: ['contains gluten'],
        error:
          'Dietary tags cannot contain prepared without gluten and contains gluten.',
      },
    }

    tagsDietaryPreference.forEach((pref) => {
      const excls = prefContras[pref]
      if (
        excls &&
        tagsDietaryPreference.find((p) => excls.exclude.includes(p))
      ) {
        errors.push(excls['error'])
      }
    })

    // Only allow a single GO meal type to be set to true at a time
    const allMealTypes = this.state.allGroupOrderMealTypes || []
    let goMealTypeCount = 0
    allMealTypes.forEach((mealType) => {
      const stateKey = this.getGOMealTypeKey(mealType)
      if (this.state[stateKey]) {
        goMealTypeCount++
      }
    })

    if (marketType === MarketTypes.GROUP_ORDER) {
      if (goMealTypeCount === 0) {
        errors.push('Group Order menu items require a GO meal type tag.')
      } else if (goMealTypeCount > 1) {
        errors.push('Group Order menu items can have only 1 GO meal type tag.')
      }
    }

    if (errors.length == 0) {
      return true
    } else {
      this.props.displayAlert({
        title: 'Validation Error',
        message: errors.join(' '),
      })

      return false
    }
  }

  onChildMenuItemXClick = async (id, name) => {
    const { deleteChildMenuItem } = this.props
    const { marketType } = this.state

    let isSuccess = true
    // do not delete child menu item if parent is for group order
    if (marketType !== MarketTypes.GROUP_ORDER) {
      isSuccess = await deleteChildMenuItem({ id, name })
    }

    if (isSuccess) {
      const { childMenuItems } = this.state
      const filtered = childMenuItems.filter((item) => item.id !== id)
      this.setState({ childMenuItems: filtered })
    }
  }

  onSelectGroupOrderAddOn = (item) => {
    const { childMenuItems, chefMap, selectedChef } = this.state
    this.setState({
      selectedAddOnItem: item,
      childMenuItems: [...childMenuItems, item],
      chefMap: {
        ...chefMap,
        [item.id]: selectedChef.name,
      },
    })
  }

  renderChildItemSection = () => {
    const {
      id,
      marketType,
      childMenuItems,
      selectedChef,
      selectedAddOnItem,
      chefMap,
    } = this.state
    const { loadChefs, loadGroupOrderAddOnItems } = this.props

    return (
      <FlexContainer flexDirection="column">
        {marketType !== MarketTypes.GROUP_ORDER ? (
          <Fragment>
            {id && (
              <LinkText
                onClick={this.onCreateChildMenuItem}
                label="Add Child Menu Item"
                fontSize="13px"
              />
            )}
            <YSpacing height="20px" />
            {childMenuItems.length > 0 && (
              <FlexContainer flexDirection="column">
                <Label>Child Menu Items (Click to edit)</Label>
                <FlexContainer flexDirection="column">
                  {childMenuItems.map((childMenuItem, i) => {
                    const {
                      allergensAndDescriptionVerified,
                      completed,
                      message,
                    } = this.getChildItemValidations(childMenuItem)

                    return (
                      <ChildItem key={i}>
                        <span
                          className="close-x"
                          onClick={() =>
                            this.onChildMenuItemXClick(
                              childMenuItem.id,
                              childMenuItem.name,
                            )
                          }
                        >
                          ✕
                        </span>
                        <p
                          key={i}
                          onClick={this.onLoadMenuItem(childMenuItem.id)}
                        >
                          <span className="child-category">
                            {childMenuItem.subMenuItemCategory}
                          </span>
                          {childMenuItem.name}{' '}
                          <span
                            style={{
                              color: completed
                                ? '#4ad54a'
                                : allergensAndDescriptionVerified
                                  ? 'orange'
                                  : 'red',
                            }}
                          >
                            {message}
                          </span>
                        </p>
                      </ChildItem>
                    )
                  })}
                </FlexContainer>
              </FlexContainer>
            )}
          </Fragment>
        ) : (
          <Fragment>
            {id && marketType === 'Group Order' && (
              <Fragment>
                <FlexContainer
                  width="100%"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Label>Include Group Add-On Items</Label>
                  <TooltipModal
                    unicode="&#9432;"
                    width="400px"
                    information="This is currently available for Group Order Menu Items only."
                  />
                </FlexContainer>
                <YSpacing height="20px" />
                <AutocompleteInput
                  label="Select Chefs to Pick Group Order Add-On Items From"
                  displayAttribute="name"
                  width="31%"
                  loaderFunction={loadChefs}
                  value={selectedChef && selectedChef.name}
                  onSelect={(chef) => this.setState({ selectedChef: chef })}
                  filters={ADD_ON_CHEF_FILTERS}
                />
                {selectedChef && (
                  <Fragment>
                    <YSpacing height="20px" />
                    <AutocompleteInput
                      label="Search Menu Items"
                      displayAttribute="name"
                      width="31%"
                      loaderFunction={loadGroupOrderAddOnItems}
                      value={selectedAddOnItem && selectedAddOnItem.name}
                      onSelect={this.onSelectGroupOrderAddOn}
                      filters={{
                        ...ADD_ON_ITEM_FILTERS,
                        chef_ids: [selectedChef.id],
                        excluded_ids: [
                          id,
                          ...childMenuItems.map((item) => item.id),
                        ],
                      }} // add group order add-on filter by meal type in the menu item detail
                    />
                  </Fragment>
                )}
              </Fragment>
            )}
            <YSpacing height="20px" />
            {childMenuItems.length > 0 && (
              <FlexContainer flexDirection="column">
                <Label>Group Order Add-On Items</Label>
                <FlexContainer flexDirection="column">
                  {childMenuItems.map((childMenuItem, i) => (
                    <ChildItem key={i}>
                      <span
                        className="close-x"
                        onClick={() =>
                          this.onChildMenuItemXClick(
                            childMenuItem.id,
                            childMenuItem.name,
                          )
                        }
                      >
                        ✕
                      </span>
                      <p key={childMenuItem.id}>
                        {childMenuItem.name} |{' '}
                        {`Chef ${
                          chefMap[childMenuItem.id] || childMenuItem.chefName
                        }`}
                      </p>
                    </ChildItem>
                  ))}
                </FlexContainer>
              </FlexContainer>
            )}
          </Fragment>
        )}
      </FlexContainer>
    )
  }

  renderLastVerification = () => {
    const { pToMenuItemVerifications } = this.props
    const {
      childMenuItems,
      marketType,
      descriptionVerifiedAt,
      descriptionVerifier,
      allergensVerifiedAt,
      allergensVerifier,
      imageVerifiedAt,
      imageVerifier,
      servingDetailsVerifiedAt,
      servingDetailsVerifier,
    } = this.state

    const isGroupOrderMenuItem = marketType == MarketTypes.GROUP_ORDER
    const verificationData = [
      { date: allergensVerifiedAt, verifier: allergensVerifier },
      { date: descriptionVerifiedAt, verifier: descriptionVerifier },
      { date: servingDetailsVerifiedAt, verifier: servingDetailsVerifier },
    ]
    if (isGroupOrderMenuItem) {
      verificationData.push({ date: imageVerifiedAt, verifier: imageVerifier })
    }

    const parentVerifications = isGroupOrderMenuItem ? 4 : 3
    let completedChildVerifications = 0
    let totalApplicableChildVerifications = 0

    let allAllergensAndDescriptionVerified = false
    childMenuItems.forEach((item) => {
      const {
        marketType,
        descriptionVerifiedAt,
        allergensVerifiedAt,
        servingDetailsVerifiedAt,
        imageVerifiedAt,
        descriptionVerifier,
        allergensVerifier,
        servingDetailsVerifier,
        imageVerifier,
      } = item
      const verifications = pToMenuItemVerifications(item)
      const applicableVerifications = verifications.length
      const completedVerications = verifications.filter((v) => v).length
      allAllergensAndDescriptionVerified =
        item.allergensVerified && item.descriptionVerified

      totalApplicableChildVerifications += applicableVerifications
      completedChildVerifications += completedVerications
      if (completedVerications === applicableVerifications) {
        const verificationDetails = [
          {
            date: descriptionVerifiedAt,
            verifier: descriptionVerifier,
          },
          {
            date: allergensVerifiedAt,
            verifier: allergensVerifier,
          },
          {
            date: servingDetailsVerifiedAt,
            verifier: servingDetailsVerifier,
          },
        ]
        if (marketType === MarketTypes.GROUP_ORDER) {
          verificationDetails.push({
            data: imageVerifiedAt,
            verifier: imageVerifier,
          })
        }
        verificationData.push(...verificationDetails)
      }
    })

    const { date, verifier } = this.getLastVerification(verificationData)

    if (!date || !verifier) {
      return <></>
    }

    if (completedChildVerifications !== totalApplicableChildVerifications) {
      return (
        <strong
          style={{
            color: allAllergensAndDescriptionVerified ? 'orange' : 'red',
            display: 'flex',
            marginLeft: '20px',
            paddingTop: '3px',
          }}
        >
          Menu Item Audit Incomplete (Parent Menu Item (
          {`${parentVerifications}/${parentVerifications}`}); Child Items (
          {`${completedChildVerifications}/${totalApplicableChildVerifications}`}
          ))
        </strong>
      )
    }

    return (
      <strong
        style={{
          color: '#4ad54a',
          display: 'flex',
          alignItems: 'center',
          minWidth: '530px',
        }}
      >
        <FaCheckCircle
          size={20}
          color={'#4ad54a'}
          style={{ marginLeft: '10px', marginRight: '10px' }}
        />
        Last Audit Completed by {`${verifier.firstName} ${verifier.lastName}`}{' '}
        {Moment(date).format('MM/DD/YYYY')}
      </strong>
    )
  }

  render() {
    const {
      calculateChefPrice,
      calculateChefPriceFromRetail,
      errors,
      chefType,
      pToMenuItemVerifications,
    } = this.props
    const {
      allNonPlatterPackagings,
      allPlatterPackagings,
      allDietaryPreferences,
      allMealTypes,
      allServingUtensils,
      allMarketTypes,
      allGroupOrderMealTypes,
      chefPrice,
      countPerShelf,
      description,
      vendorDescription,
      id,
      images,
      imageSourceType,
      isApproved,
      isDestroyed,
      isEcoPackaging,
      isEnabled,
      isHot,
      marketplaceAddOn,
      marketPrice,
      pricePerPersonCalc,
      retailPrice,
      mealType,
      marketType,
      name,
      minQty,
      dailyOrderLimit,
      packagingId,
      servingsPerPkg,
      servingUtensilId,
      servingWeightOzs,
      servingSize,
      marketPriceMarkupPercentage,
      showMarkupPercentagePopup,
      internalNotes,
      vendorNotes,
    } = this.state

    const prefErrs =
      errors && errors['itemDetailable.tagsDietaryPreferenceList']

    const title = id ? 'Edit Menu Item' : 'New Menu Item'
    const isMarketplaceAddOn = marketplaceAddOn && !marketplaceAddOn.destroy

    const validMarketTypes = allMarketTypes.filter((t) => t !== 'Home')

    const isGroupOrderMenuItem = marketType == MarketTypes.GROUP_ORDER
    const verifications = pToMenuItemVerifications(this.state)
    const applicableVerifications = verifications.length
    const verificationsCompleted = verifications.filter((v) => v).length
    const allVerificationsCompleted =
      verificationsCompleted === applicableVerifications

    return (
      <Modal
        title={title}
        hideModal={this.onHide}
        color="#001940"
        width="800px"
      >
        <FlexContainer
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-start"
        >
          <OrderFormSectionTitle>Menu Item Details</OrderFormSectionTitle>
          {allVerificationsCompleted && this.renderLastVerification()}
        </FlexContainer>
        <YSpacing height="20px" />
        <FlexContainer justifyContent="space-between" flexWrap="wrap">
          <FlexContainer
            flexWrap="wrap"
            justifyContent="space-between"
            width="100%"
          >
            <Input
              width="31%"
              label="Menu Item Name"
              value={name}
              error={errors.name}
              onChange={this.onChange('name')}
            />
            <QuantityInput
              width="31%"
              label="Min Quantity"
              value={minQty}
              onChange={(minQty) => this.setState({ minQty })}
            />
            {dailyOrderLimit === null ? (
              <FlexContainer width="31%" flexDirection="column">
                <Label>Daily Order Limit</Label>
                <YSpacing height="5px" />
                <LinkText
                  onClick={() => this.setState({ dailyOrderLimit: 0 })}
                  label="No Limit"
                  fontSize="13px"
                />
              </FlexContainer>
            ) : (
              <FlexContainer flexDirection="column" width="31%">
                <QuantityInput
                  width="100%"
                  label="Daily Order limit"
                  value={dailyOrderLimit}
                  onChange={(dailyOrderLimit) =>
                    this.setState({ dailyOrderLimit })
                  }
                />
                <LinkText
                  onClick={() => this.setState({ dailyOrderLimit: null })}
                  label="Set to No Limit"
                  fontSize="13px"
                />
              </FlexContainer>
            )}
            <Input
              width="31%"
              label="Serving Size"
              value={servingSize}
              marginBottom="5px"
              error={errors.servingSize}
              onChange={this.onChange('servingSize')}
            />
            <Input
              width="31%"
              label="Servings Per Package"
              value={servingsPerPkg}
              type="number"
              marginBottom="5px"
              error={errors.servingsPerPkg}
              onChange={(e) =>
                this.onChangeMenuItem(
                  'servingsPerPkg',
                  parseInt(e.target.value),
                )
              }
            />
            <ServingWeightForm>
              <label>Serving Weights (In ounces)</label>
              <input
                type="number"
                list="servingWeights"
                value={servingWeightOzs}
                onChange={(e) =>
                  this.onChangeMenuItem(
                    'servingWeightOzs',
                    parseInt(e.target.value),
                  )
                }
              />
              <datalist id="servingWeights">
                {ServingWeights.map((o) => (
                  <option key={o.value} value={o.value} />
                ))}
              </datalist>
            </ServingWeightForm>
            <Dropdown
              label="Serving Utensils"
              error={errors.servingUtensil}
              width="31%"
              onChange={(e) =>
                this.onChangeMenuItem('servingUtensilId', e.target.value)
              }
            >
              {allServingUtensils.map((o) => (
                <option
                  key={o.value}
                  value={o.value}
                  selected={servingUtensilId === o.value}
                >
                  {o.text}
                </option>
              ))}
            </Dropdown>
            <FlexContainer flexDirection="column" width="31%">
              <CurrencyInput
                width="100%"
                label="Chef Price"
                error={errors.cost}
                value={chefPrice}
                onChange={(value) => this.onChangeMenuItem('chefPrice', value)}
              />
              <FlexContainer
                flexDirection="row"
                width="100%"
                justifyContent="center"
              >
                <div className="flex flex-row justify-center text-center">
                  <p className="text-xs font-extrabold mr-2">Calc. From:</p>
                  <LinkText
                    fontSize="12px"
                    onClick={() =>
                      this.onChangeMenuItem(
                        'chefPrice',
                        calculateChefPrice(
                          marketPrice,
                          marketPriceMarkupPercentage,
                        ),
                      )
                    }
                    label="Market"
                  />
                  <div className="mx-1">
                    <p className="text-xs font-extrabold">/</p>
                  </div>
                  <LinkText
                    fontSize="12px"
                    onClick={() =>
                      this.onChangeMenuItem(
                        'chefPrice',
                        calculateChefPriceFromRetail(retailPrice),
                      )
                    }
                    label="Retail"
                  />
                  <p className="text-xs font-extrabold ml-2">Price</p>
                </div>
              </FlexContainer>
            </FlexContainer>
            <FlexContainer flexDirection="column" width="31%">
              <CurrencyInput
                width="100%"
                label="Market Price"
                error={errors.price}
                value={marketPrice}
                onChange={(value) =>
                  this.onChangeMenuItem('marketPrice', value)
                }
              />
              <LinkText
                onClick={() =>
                  this.setState({ showMarkupPercentagePopup: true })
                }
                label="Calc. Market Price from Chef Price"
                fontSize="12px"
              />
              <dialog
                open={showMarkupPercentagePopup}
                style={{ zIndex: '4000' }}
              >
                <p>Please select market markup percentage:</p>
                <form>
                  <div className="radio">
                    <label>
                      <input
                        type="radio"
                        value="0.15"
                        checked={marketPriceMarkupPercentage === 0.15}
                        onChange={this.onChangeMarkupPercentage}
                      />{' '}
                      15%
                    </label>
                  </div>
                  <div className="radio">
                    <label>
                      <input
                        type="radio"
                        value="0.2"
                        checked={marketPriceMarkupPercentage === 0.2}
                        onChange={this.onChangeMarkupPercentage}
                      />{' '}
                      20%
                    </label>
                  </div>
                  <div className="radio">
                    <label>
                      <input
                        type="radio"
                        value="0.3"
                        checked={marketPriceMarkupPercentage === 0.3}
                        onChange={this.onChangeMarkupPercentage}
                      />{' '}
                      30%
                    </label>
                  </div>
                  <div className="radio">
                    <label>
                      <input
                        type="radio"
                        value="0.4"
                        checked={marketPriceMarkupPercentage === 0.4}
                        onChange={this.onChangeMarkupPercentage}
                      />{' '}
                      40%
                    </label>
                  </div>
                  <div className="radio">
                    <label>
                      <input
                        type="radio"
                        value="0.5"
                        checked={marketPriceMarkupPercentage === 0.5}
                        onChange={this.onChangeMarkupPercentage}
                      />{' '}
                      50%
                    </label>
                  </div>
                </form>
                <div className="flex justify-between">
                  <Button
                    onClick={this.onSaveMarketPriceMarkupPercentage}
                    label="Save"
                  />
                  <Button
                    className="ml-full"
                    onClick={this.onCancelMarketPriceMarkupPercentage}
                    label="Cancel"
                  />
                </div>
              </dialog>
            </FlexContainer>
            <Dropdown
              label="Market Price Calculation"
              width="31%"
              paddingTop="17px"
              onChange={(e) =>
                this.onChangeMenuItem('pricePerPersonCalc', e.target.value)
              }
              value={pricePerPersonCalc}
            >
              {AllMpCalculationTypes.map((o) => (
                <option key={o.id} value={o.value}>
                  {o.label}
                </option>
              ))}
            </Dropdown>
            <FlexContainer width="31%" flexDirection="column">
              {chefType !== 'Chef' && (
                <CurrencyInput
                  width="100%"
                  label="Retail Price"
                  paddingTop="17px"
                  error={errors.retailPrice}
                  value={retailPrice}
                  onChange={(value) =>
                    this.onChangeMenuItem('retailPrice', value)
                  }
                />
              )}
            </FlexContainer>
            {isGroupOrderMenuItem && (
              <QuantityInput
                width="31%"
                label="Count Per Shelf"
                subLabel=" (Group Order Items Only)"
                value={countPerShelf}
                onChange={(countPerShelf) => this.setState({ countPerShelf })}
              />
            )}
          </FlexContainer>
          <FlexContainer width="31%" flexDirection="column">
            <Checkbox
              label="Is Hot"
              value="isHot"
              checked={isHot}
              marginTop="24px"
              onChange={this.onChangeCheckbox}
            />
            <Checkbox
              label="Is Eco Friendly Packaging"
              value="isEcoPackaging"
              checked={isEcoPackaging}
              marginTop="24px"
              onChange={this.onChangeCheckbox}
            />
          </FlexContainer>
          <YSpacing height="20px" />
          <FlexContainer
            width="100%"
            alignItems="center"
            justifyContent="flex-start"
          >
            <TextArea
              height="50px"
              width="80%"
              label="Internal Notes"
              value={internalNotes}
              onChange={this.onChange('internalNotes')}
            />
            <TooltipModal
              unicode="&#9432;"
              width="200px"
              information={
                'This internal notes feature is used for the CN team to provide any extra notes for a menu item and that it is editable only by the CN team and read only by the Sales team.'
              }
            />
          </FlexContainer>
          <YSpacing height="20px" />
          <FlexContainer
            width="100%"
            alignItems="center"
            justifyContent="flex-start"
          >
            <TextArea
              height="50px"
              width="80%"
              label="Vendor Notes"
              value={vendorNotes}
              onChange={this.onChange('vendorNotes')}
            />
            <TooltipModal
              unicode="&#9432;"
              width="200px"
              information={
                'These vendor notes are meant to convey information directly to vendor. Examples may include ready to heat preparations or other food stabiliy / preparation requirements.'
              }
            />
          </FlexContainer>
          <YSpacing height="20px" />
          {marketType === MarketTypes.GROUP_ORDER && (
            <TextArea
              error={errors.vendorDescription}
              height="50px"
              width="100%"
              label="Vendor Facing Description"
              value={vendorDescription}
              onChange={this.onChange('vendorDescription')}
            />
          )}
          <YSpacing height="20px" />
          <TextArea
            error={errors.description}
            height="50px"
            width="100%"
            label="Customer/Client Facing Description"
            value={description}
            onChange={this.onChange('description')}
          />
          <YSpacing height="20px" />
          <FlexContainer justifyContent="space-between" width="100%">
            <FlexContainer flexDirection="column" width="31%">
              {errors.mealType && (
                <div className="error-message"> {errors.mealType} </div>
              )}
              <Label>Dish Type</Label>
              <YSpacing height="5px" />
              <FlexContainer flexDirection="row" alignItems="center">
                <FlexContainer flexDirection="column">
                  {allMealTypes.map((meal) => (
                    <RadioButton
                      marginBottom="5px"
                      name="mealType"
                      value={meal}
                      key={meal}
                      checked={meal === mealType}
                      onChange={this.onChangeRadioButton}
                    />
                  ))}
                </FlexContainer>
                <TooltipModal
                  unicode="&#9432;"
                  width="400px"
                  information={
                    'Sides that are considered a Starch based side, should be designated as a "starch side". Vegetable based sides should be designated as "Vegetable side".'
                  }
                />
                <XSpacing width="40px" />
              </FlexContainer>
            </FlexContainer>
            <FlexContainer flexDirection="column" width="31%">
              {errors.marketType && (
                <div className="error-message"> {errors.marketType} </div>
              )}
              <Label>Market Type</Label>
              <YSpacing height="5px" />
              {validMarketTypes.map((type) => (
                <RadioButton
                  marginBottom="5px"
                  name="marketType"
                  value={type}
                  key={type}
                  checked={type === marketType}
                  onChange={this.onMarketTypeChange}
                />
              ))}
            </FlexContainer>
            <FlexContainer width="31%" flexDirection="column">
              {marketType === MarketTypes.GROUP_ORDER && (
                <Fragment>
                  <Label>Group Order Meal Type</Label>
                  {allGroupOrderMealTypes.map((type) => {
                    return (
                      <Checkbox
                        key={type}
                        label={type}
                        value={type}
                        checked={this.state[this.getGOMealTypeKey(type)]}
                        onChange={this.onChangeGroupOrderMealType}
                        marginBottom="5px"
                      />
                    )
                  })}
                </Fragment>
              )}
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
        <YSpacing height="20px" />
        {this.renderChildItemSection()}
        <YSpacing height="20px" />
        <FlexContainer justifyContent="space-between" width="100%">
          <FlexContainer width="31%" flexDirection="column">
            <Label>Concepts</Label>
            <ul>
              {this.props.menuItem.concepts &&
                this.props.menuItem.concepts.split(', ').map((concept, i) => {
                  return <li key={i}>{concept}</li>
                })}
            </ul>
          </FlexContainer>
        </FlexContainer>
        <YSpacing height="10px" />
        <FlexContainer
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
        >
          <FileUpload
            width="31%"
            marginBottom="10px"
            label="Upload Image"
            onChange={(e) => this.onAddImage(e.target.files[0])}
          />
          <XSpacing width="10px" />
          <Checkbox
            label="AI Image"
            onChange={() =>
              this.setState({
                imageSourceType:
                  imageSourceType === ImageSourceTypes.AI
                    ? null
                    : ImageSourceTypes.AI,
              })
            }
            checked={imageSourceType === ImageSourceTypes.AI}
          />
          <Checkbox
            label="Actual Image"
            onChange={() =>
              this.setState({
                imageSourceType:
                  imageSourceType === ImageSourceTypes.Actual
                    ? null
                    : ImageSourceTypes.Actual,
              })
            }
            checked={imageSourceType === ImageSourceTypes.Actual}
          />
        </FlexContainer>
        <p style={{ fontStyle: 'italic' }}>
          {' '}
          *Must select type of image in order to save.{' '}
          {isGroupOrderMenuItem ? imageCopy : ''}
        </p>
        {images.length > 0 && (
          <Carousel>
            {images.map((image, i) => (
              <Carousel.Item key={i}>
                <div>
                  {image.destroy ? (
                    <img src={removedImage} />
                  ) : (
                    <img src={image.thumborUrl} />
                  )}
                  <button onClick={() => this.onDeleteImage(i)}>
                    {' '}
                    Delete / Undo{' '}
                  </button>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        )}
        <YSpacing height="20px" />
        <FlexContainer justifyContent="space-between" width="100%">
          <FlexContainer flexDirection="column" width="31%">
            <Label>Allergens/Dietary Preferences</Label>
            <YSpacing height="5px" />
            {prefErrs && <div className="error-message"> {prefErrs} </div>}
            {allDietaryPreferences.map((p) => (
              <Checkbox
                label={p}
                key={p}
                value={p}
                marginBottom="5px"
                checked={this.state[this.getDietaryPrefKey(p)]}
                onChange={this.onChangeDietaryPref}
              />
            ))}
          </FlexContainer>
          <FlexContainer flexDirection="column" width="31%">
            <Label>Packaging</Label>
            <YSpacing height="5px" />
            <RadioButton
              marginBottom="5px"
              name="packagingId"
              value={null}
              label={'No Packaging'}
              key={'noPackaging'}
              checked={!packagingId}
              onChange={() => this.setState({ packagingId: null })}
            />
            {allNonPlatterPackagings.map((p) => {
              const { name, id } = p

              return (
                <RadioButton
                  marginBottom="5px"
                  name="packagingId"
                  value={id}
                  label={name}
                  key={id}
                  checked={id === packagingId}
                  onChange={this.onChangeRadioButton}
                />
              )
            })}
            <Label>Platters</Label>
            <YSpacing height="5px" />
            {allPlatterPackagings.map((p) => {
              const { name, id } = p

              return (
                <RadioButton
                  marginBottom="5px"
                  name="packagingId"
                  value={id}
                  label={name}
                  key={id}
                  checked={id === packagingId}
                  onChange={this.onChangeRadioButton}
                />
              )
            })}
          </FlexContainer>
          <FlexContainer flexDirection="column" width="31%">
            <Label>Menu Item Status</Label>
            <YSpacing height="5px" />
            <Checkbox
              label="Marketplace Enabled"
              value="isEnabled"
              checked={isEnabled}
              marginBottom="5px"
              onChange={this.onChangeCheckbox}
            />
            <Checkbox
              label="Approved"
              value="isApproved"
              checked={isApproved}
              onChange={this.onChangeCheckbox}
            />
            <YSpacing height="30px" />
            <Label>Marketplace Options</Label>
            <YSpacing height="5px" />
            <Checkbox
              label="Marketplace Add On"
              checked={isMarketplaceAddOn}
              onChange={this.onChangeMarketplaceAddOn()}
            />
          </FlexContainer>
        </FlexContainer>
        <YSpacing height="10px" />
        {id && (
          <>
            <DividerLine height="2px" />
            <YSpacing height="10px" />
            <MenuItemVerifications
              menuItem={this.state}
              onChange={(newState) => this.setState(newState)}
              pToMenuItemVerifications={this.props.pToMenuItemVerifications}
            />
          </>
        )}
        <YSpacing height="20px" />
        <FlexContainer alignItems="center" justifyContent="flex-end">
          {id && !isDestroyed && (
            <AuthorizedInteractable roles={['master admin', 'chef lead']}>
              <LinkText
                color={colors.violet}
                onClick={this.onDelete}
                label="Archive"
              />
            </AuthorizedInteractable>
          )}
          {id && isDestroyed && (
            <LinkText
              color={colors.violet}
              onClick={this.onRestoreItem}
              label="Un-Archive (Restore)"
            />
          )}
          <XSpacing width="20px" />
          <LinkText
            color={colors.gray300}
            onClick={this.onHide}
            label="Cancel"
          />
          <XSpacing width="20px" />
          <AuthorizedInteractable roles={['master admin', 'chef lead']}>
            <Button onClick={this.onSave} label="Save" />
          </AuthorizedInteractable>
        </FlexContainer>
      </Modal>
    )
  }
}

EditMenuItemModal.propTypes = {
  chefType: PropTypes.string,
  isCopy: PropTypes.bool,
  addOnChef: PropTypes.bool,
  errors: PropTypes.object,
  menuItem: PropTypes.object,
  show: PropTypes.bool,
  user: PropTypes.object,

  calculateChefPrice: PropTypes.func,
  calculateChefPriceFromRetail: PropTypes.func,
  calculateMarketPrice: PropTypes.func,
  clearErrors: PropTypes.func,
  close: PropTypes.func,
  deleteMenuItem: PropTypes.func,
  deleteChildMenuItem: PropTypes.func,
  displayAlert: PropTypes.func,
  editChildMenuItem: PropTypes.func,
  loadMenuItemSettings: PropTypes.func,
  newChildMenuItem: PropTypes.func,
  saveMenuItem: PropTypes.func,
  loadMenuItems: PropTypes.func,
  loadChefs: PropTypes.func,
  loadGroupOrderAddOnItems: PropTypes.func,
  pToMenuItemVerifications: PropTypes.func,
  restoreMenuItem: PropTypes.func,
}

export default EditMenuItemModal
