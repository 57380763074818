import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { BiLeftArrow, BiRightArrow } from 'react-icons/bi'
import services from '@services'
import Modal from '@components/common/modal/Modal'
import Panel from '@components/common/Panel'
import Table from '@components/common/Table'
import XSpacing from '@components/common/XSpacing'
import YSpacing from '@components/common/YSpacing'
import LoadingIndicator from '@components/common/LoadingIndicator'
import LinkText from '@components/common/form/LinkText'
import sort from '@res/images/sort.svg'
import {
  AuthorizedInteractable,
  AuthorizedDisplay,
} from '@containers/common/auth'
import {
  Button,
  Checkbox,
  CurrencyInput,
  Dropdown,
  Input,
  RadioButton,
} from '@components/common/form'
import { BiPencil, BiTrash, BiCopyAlt } from 'react-icons/bi'
import { HiInformationCircle } from 'react-icons/hi'
import { ButtonsBar } from '@res/styledComponents/index'
import FlexContainer from '@components/common/FlexContainer'
import { CopyMenuItemModal, EditMenuItemModal } from '@containers/chef/edit'
import { CopyMenuItemsModal } from '@containers/chef/chefSections'

export class MenuItems extends Component {
  constructor(props) {
    super(props)

    this.state = {
      checkedMenuItems: [],
      dishTypes: [],
      goMealTypes: [],
      menuItems: [],
      menuItem: null,
      editModalVisible: false,
      sortAsc: true,
      editedItems: [],
      filter: '',
      marketType: '',
      mealType: '',
      menuItemRatingsMap: {},
      goMealType: '',
      inputType: '',
      isSavingEdits: false,
      selectedMenuItem: {},
      selectedDishType: '',
      showDishTypeSelector: false,
      viewArchivedMenuItems: false,
      page: 1,
      resultsPerPage: 50,
    }
  }

  componentDidMount() {
    ;(async () => {
      const { allMealTypes, allGroupOrderMealTypes } =
        await this.props.loadMenuItemSettings()
      this.setState({
        dishTypes: allMealTypes,
        goMealTypes: allGroupOrderMealTypes || [],
      })
    })()
  }
  componentWillReceiveProps(nextProps) {
    const { menuItems } = nextProps
    // Only update local menuItems if not currently saving edits
    // This prevents overwriting pending local changes during an active save process
    if (
      !!menuItems &&
      menuItems !== this.state.menuItems &&
      !this.state.isSavingEdits
    ) {
      this.setState({ menuItems }, this.checkAndLoadMenuItemRatings)
    }
  }

  checkAndLoadMenuItemRatings = () => {
    const { menuItemRatingsMap, menuItems } = this.state

    const newGoItemIds = menuItems
      .filter(
        (item) =>
          item.marketType === 'Group Order' && !menuItemRatingsMap[item.id],
      )
      .map((item) => item.id)

    if (newGoItemIds.length > 0) {
      this.loadMenuItemRatings(newGoItemIds)
    }
    this.sortMenuItemsByString('name', true)
  }

  loadMenuItemRatings = async (ids) => {
    const { loadMenuItemRatings } = this.props
    const { menuItemRatingsMap } = this.state

    const ratings = await loadMenuItemRatings(ids)

    const updatedRatings = { ...menuItemRatingsMap }
    ids.forEach((id) => {
      updatedRatings[id] = {
        averageRating: ratings[id]?.rating || 0,
        numRatings: ratings[id]?.count || 0,
      }
    })
    this.setState({ menuItemRatingsMap: updatedRatings })
  }

  sortArrayByProperty = (array, property) => {
    return array.sort((a, b) => {
      return a[property] > b[property] ? 1 : -1
    })
  }

  newMenuItem = () => {
    const { newMenuItem } = this.props
    newMenuItem()
  }

  editMenuItem = (menuItem) => {
    const { editMenuItem } = this.props
    editMenuItem(menuItem)
  }

  sortMenuItemsByString = (columnName, willReceiveProps) => {
    const { menuItems, sortAsc } = this.state
    const nextSort = willReceiveProps ? sortAsc : !sortAsc
    const getColumn = (item) =>
      item[columnName] ? item[columnName].toLowerCase() : ''
    const sortedMenuItems = menuItems.sort((a, b) => {
      const colA = getColumn(a)
      const colB = getColumn(b)

      return !nextSort ? (colB > colA ? 1 : -1) : colA > colB ? 1 : -1
    })
    this.setState({ menuItems: sortedMenuItems, sortAsc: nextSort })
  }

  onCheckMenuItem = (id, name) => () => {
    let { checkedMenuItems } = this.state
    const menuItem = { id, name }
    const idx = checkedMenuItems.findIndex(
      (tempItem) => tempItem.id === menuItem.id,
    )
    if (idx === -1) {
      checkedMenuItems = [...checkedMenuItems, menuItem]
    } else {
      checkedMenuItems = [
        ...checkedMenuItems.slice(0, idx),
        ...checkedMenuItems.slice(idx + 1),
      ]
    }
    this.setState({ checkedMenuItems })
  }

  onSearch = (e) => {
    this.setState({
      filter: e.target.value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'),
      checkedMenuItems: [],
    })
  }

  removeMenuItemIds = async () => {
    const { checkedMenuItems } = this.state
    const successfulItems = await this.props.deleteMenuItems({
      checkedMenuItems,
    })
    // filter out sucessfully archived items
    this.setState(
      {
        checkedMenuItems: checkedMenuItems.filter(
          (item) => !successfulItems[item.id],
        ),
        showLoader: false,
      },
      this.loadMenuItems,
    )
  }

  restoreMenuItemIds = async () => {
    const { checkedMenuItems } = this.state
    const success = await this.props.bulkRestoreMenuItems({ checkedMenuItems })
    if (success) {
      this.setState({ checkedMenuItems: [] })
    }
    this.setState({ showLoader: false }, this.loadMenuItems)
  }

  handleBulk = () => {
    const { viewArchivedMenuItems } = this.state
    if (viewArchivedMenuItems) {
      this.setState({ showLoader: true }, this.restoreMenuItemIds)
    } else {
      this.setState({ showLoader: true }, this.removeMenuItemIds)
    }
  }

  sortMenuItemsByNumber = (columnName) => {
    const { menuItems, sortAsc } = this.state
    const getColumn = (item) =>
      item[columnName] ? parseFloat(item[columnName].replace(/[$,]/g, '')) : 0.0
    const sortedMenuItems = menuItems.sort((a, b) => {
      const colA = getColumn(a)
      const colB = getColumn(b)

      return !sortAsc ? (colB > colA ? 1 : -1) : colA > colB ? 1 : -1
    })
    this.setState({ menuItems: sortedMenuItems, sortAsc: !sortAsc })
  }

  // TODO: this will only sort margin by the current loaded page of menu items
  sortMenuItemsByMargin = () => {
    const { menuItems, sortAsc } = this.state
    const sortedMenuItems = menuItems.sort((a, b) => {
      const marginA = this.calcMargin(a)
      const marginB = this.calcMargin(b)

      return !sortAsc
        ? marginB > marginA
          ? 1
          : -1
        : marginA > marginB
          ? 1
          : -1
    })
    this.setState({ menuItems: sortedMenuItems, sortAsc: !sortAsc })
  }

  // TODO: this will only sort boolean by the current loaded page of menu items
  sortMenuItemsByBoolean = (columnName) => {
    const { menuItems, sortAsc } = this.state
    const getColumn = (item) => (item[columnName] === true ? 1 : 0)
    const sortedMenuItems = menuItems.sort((a, b) => {
      const colA = getColumn(a)
      const colB = getColumn(b)

      return !sortAsc ? (colB > colA ? 1 : -1) : colA > colB ? 1 : -1
    })
    this.setState({ menuItems: sortedMenuItems, sortAsc: !sortAsc })
  }

  selectMenuItem = (menuItem) => (e) => {
    e.preventDefault()
    this.editMenuItem(menuItem.id)
  }

  saveMenuItems = async () => {
    const { editedItems } = this.state
    const { saveMenuItem } = this.props
    const itemsLeft = []
    for (let i = 0; i < editedItems.length; i++) {
      const item = editedItems[i]
      const didSave = await saveMenuItem(item, false, false)

      if (!didSave) {
        itemsLeft.push(item)
      }
    }

    this.setState(
      { editedItems: itemsLeft, isSavingEdits: false },
      this.loadMenuItems,
    )
  }

  clearEdits = () => {
    const { menuItems } = this.props
    const sliced = menuItems.map((i) => ({ ...i }))
    const sortedItems = this.sortArrayByProperty(sliced, 'name')
    this.setState({ editedItems: [], menuItems: sortedItems })
  }

  calcMargin = (item) => {
    const marketPrice = parseFloat(item.marketPrice.replace(/[$,]/g, ''))
    const chefPrice = parseFloat(item.chefPrice.replace(/[$,]/g, ''))
    if (marketPrice === 0.0) {
      return 0.0
    } else {
      return ((marketPrice - chefPrice) / marketPrice) * 100
    }
  }

  onChangeEditedItems = (value, id, property) => {
    const { menuItems, editedItems } = this.state
    const items = menuItems.map((i) => ({ ...i }))
    const index = menuItems.findIndex((item) => item.id === id)
    items[index][property] = value
    items[index].edited = true
    const currentEditIndex = editedItems.findIndex((item) => item.id === id)
    if (currentEditIndex >= 0) {
      editedItems[currentEditIndex] = items[index]
    } else {
      editedItems.push(items[index])
    }
    this.setState({ menuItems: items, editedItems })
  }

  handleChangingDishType = async (item) => {
    const { loadMenuItem } = this.props
    const menuItem = await loadMenuItem(item.id)
    this.setState({ showDishTypeSelector: true, selectedMenuItem: menuItem })
  }

  handleSavingDishType = async () => {
    const { selectedMenuItem, selectedDishType } = this.state
    const { saveMenuItem } = this.props
    const updatedItem = { ...selectedMenuItem, mealType: selectedDishType }

    const savedMenuItem = await saveMenuItem(updatedItem, false, true)
    if (savedMenuItem) {
      const menuItems = this.state.menuItems.map((item) => {
        if (item.id === savedMenuItem.id) {
          return savedMenuItem
        } else {
          return item
        }
      })
      this.setState(
        {
          menuItems,
          selectedDishType: '',
          showDishTypeSelector: false,
        },
        this.loadMenuItems,
      )
    }
  }

  renderInputDeails = () => {
    services.UIService.Alert.showPopup({
      message:
        'Custom items are menu items created on a Proposal or Sales Order. Manual items are menu items manually created from this page.',
    })
  }

  loadMenuItems = async () => {
    const { loadChefMenuItems, chefId } = this.props
    const {
      page,
      resultsPerPage,
      filter,
      marketType,
      mealType,
      goMealType,
      inputType,
      viewArchivedMenuItems,
    } = this.state
    this.setState({ showLoader: true })
    const req = {
      chefId,
      page,
      resultsPerPage,
      search: filter,
      marketType,
      mealType,
      goMealType,
      inputType,
      viewArchivedItems: viewArchivedMenuItems,
      sort: 'name',
    }
    await loadChefMenuItems(req)
    this.setState({ showLoader: false, checkedMenuItems: [] })
  }

  updatePage = (newPage) => {
    this.setState({ page: newPage })
  }

  render() {
    const {
      checkedMenuItems,
      dishTypes,
      goMealTypes,
      menuItems,
      editedItems,
      marketType,
      mealType,
      goMealType,
      inputType,
      showLoader,
      viewArchivedMenuItems,
    } = this.state
    const {
      menuItems: loadedItems,
      showEditItemModal,
      showCopyMenuItemModal,
      showCopyMenuItemsModal,
    } = this.props
    const areMenuItemsLoaded = !!loadedItems
    const areMenuItemsEmpty = menuItems && menuItems.length === 0
    const itemsChecked = checkedMenuItems.length > 0
    const displayedMenuItems = menuItems || []
    const marketTypes = ['Office', 'Group Order']
    const inputTypes = ['Custom', 'Manual']

    return (
      <div style={{ width: '1800px' }}>
        {this.state.showDishTypeSelector && (
          <Modal
            title="Select new Dish Type"
            hideModal={() => this.setState({ showDishTypeSelector: false })}
            color="blue"
          >
            {dishTypes.map((type) => (
              <RadioButton
                marginBottom="5px"
                name="DishType"
                value={type}
                key={type}
                checked={type === this.state.selectedDishType}
                onChange={() => this.setState({ selectedDishType: type })}
              />
            ))}
            <YSpacing height="20px" />
            <Button
              label="Save Menu Item's Dish Type"
              onClick={() => this.handleSavingDishType()}
            />
          </Modal>
        )}
        {showEditItemModal && (
          <EditMenuItemModal loadMenuItems={this.loadMenuItems} />
        )}
        {showCopyMenuItemModal && (
          <CopyMenuItemModal loadMenuItems={this.loadMenuItems} />
        )}
        {showCopyMenuItemsModal && (
          <CopyMenuItemsModal loadMenuItems={this.loadMenuItems} />
        )}

        <YSpacing height="10px" />
        <ButtonsBar>
          <FlexContainer flexDirection="row" alignItems="flex-start">
            <AuthorizedInteractable roles={['master admin', 'chef lead']}>
              <button
                style={{ width: '200px' }}
                onClick={(event) => {
                  event.preventDefault()
                  this.newMenuItem()
                }}
              >
                <BiPencil />
                Add New Menu Item
              </button>
            </AuthorizedInteractable>
            <AuthorizedInteractable roles={['master admin', 'chef lead']}>
              <button
                style={{ width: '300px' }}
                onClick={() => {
                  this.props.copyMenuItems()
                }}
              >
                <BiCopyAlt />
                Copy Menu Items From Chefs
              </button>
            </AuthorizedInteractable>
            {itemsChecked && (
              <AuthorizedInteractable roles={['master admin', 'chef lead']}>
                <button onClick={this.handleBulk} style={{ width: '200px' }}>
                  {!viewArchivedMenuItems && <BiTrash />}
                  {viewArchivedMenuItems
                    ? 'Restore Checked Items'
                    : 'Archive Checked Items'}
                </button>
              </AuthorizedInteractable>
            )}
          </FlexContainer>
        </ButtonsBar>
        <div className="search-panel">
          <FlexContainer>
            <Input
              label="Filter Menu Items"
              onChange={this.onSearch}
              width="250px"
            />
            <XSpacing width="20px" />
            <Dropdown
              label="Filter Market Type"
              width="200px"
              value={marketType}
              onChange={(e) => this.setState({ marketType: e.target.value })}
            >
              <option value="">No Filter</option>
              {marketTypes.map((market) => (
                <option key={market} value={market}>
                  {market}
                </option>
              ))}
            </Dropdown>
            <XSpacing width="20px" />
            <Dropdown
              label="Filter Dish Type"
              width="200px"
              value={mealType}
              onChange={(e) => this.setState({ mealType: e.target.value })}
            >
              <option value="">No Filter</option>
              {dishTypes.map((meal) => (
                <option key={meal} value={meal}>
                  {meal}
                </option>
              ))}
            </Dropdown>
            <XSpacing width="20px" />
            <Dropdown
              label="Filter Group Order Meal Type"
              width="230px"
              value={goMealType}
              onChange={(e) => this.setState({ goMealType: e.target.value })}
            >
              <option value="">No Filter</option>
              {goMealTypes.map((mealType) => (
                <option key={mealType} value={mealType}>
                  {mealType}
                </option>
              ))}
            </Dropdown>
            <XSpacing width="20px" />
            <Dropdown
              label="Filter by Input Type"
              width="200px"
              value={inputType}
              onChange={(e) => this.setState({ inputType: e.target.value })}
            >
              <option value="">No Filter</option>
              {inputTypes.map((input) => (
                <option key={input} value={input}>
                  {input}
                </option>
              ))}
            </Dropdown>
          </FlexContainer>
          <FlexContainer alignItems="center">
            <Button
              label="Search Items"
              backgroundColor={'#0288d1'}
              onClick={this.loadMenuItems}
              width="150px"
            />
            <XSpacing width="25px" />
            <div className="pagination-controls">
              {this.state.page > 2 && (
                <>
                  <button
                    className="pagination-button"
                    onClick={() => this.updatePage(1)}
                  >
                    <BiLeftArrow />
                    <BiLeftArrow />
                  </button>
                  <XSpacing width="5px" />
                </>
              )}
              {this.state.page > 1 && (
                <button
                  className="pagination-button"
                  onClick={() => this.updatePage(this.state.page - 1)}
                >
                  <BiLeftArrow />
                </button>
              )}
              <XSpacing width="5px" />
              <p className="pagination-text">Page {this.state.page}</p>
              <XSpacing width="5px" />
              {menuItems.length === this.state.resultsPerPage && (
                <button
                  onClick={() => this.updatePage(this.state.page + 1)}
                  className="pagination-button"
                  disabled={menuItems.length < this.state.resultsPerPage}
                >
                  <BiRightArrow />
                </button>
              )}
            </div>
            <XSpacing width="10px" />
            <div className="results-info">
              <p>Showing {this.state.resultsPerPage} Per Page</p>
            </div>
            <XSpacing width="10px" />
            <Checkbox
              checked={this.state.viewArchivedMenuItems}
              onClick={() =>
                this.setState({
                  viewArchivedMenuItems: !this.state.viewArchivedMenuItems,
                })
              }
              label="View Archived Menu Items"
            />
          </FlexContainer>
        </div>
        <YSpacing height="10px" />
        <Panel width="100%" maxWidth="auto">
          <Table>
            <thead>
              <tr>
                <th>
                  <p className="flex">Avg Rating</p>
                </th>
                <th
                  className="pointer"
                  onClick={() => this.sortMenuItemsByString('name')}
                >
                  <p className="flex">
                    Item Name <img className="item-sort" src={sort} />
                  </p>
                </th>
                <th
                  className="pointer nowrap"
                  onClick={() => this.sortMenuItemsByString('marketType')}
                >
                  <p className="flex">
                    Market Type <img className="item-sort" src={sort} />
                  </p>
                </th>
                <th
                  className="pointer nowrap"
                  onClick={() => this.sortMenuItemsByString('mealType')}
                >
                  <p className="flex">
                    Dish Type <img className="item-sort" src={sort} />
                  </p>
                </th>
                <th
                  className="pointer nowrap"
                  onClick={() =>
                    this.sortMenuItemsByString('tagsGroupOrderMealTypeList')
                  }
                >
                  <p className="flex">
                    GO Meal Type <img className="item-sort" src={sort} />
                  </p>
                </th>
                <th
                  className="pointer nowrap"
                  onClick={() => this.sortMenuItemsByString('tagsCuisineList')}
                >
                  <p className="flex">
                    Cuisine Types <img className="item-sort" src={sort} />
                  </p>
                </th>
                <th className="pointer nowrap">
                  <p className="flex">Is Hot</p>
                </th>
                <th
                  className="pointer nowrap"
                  onClick={() => this.sortMenuItemsByNumber('chefPrice')}
                >
                  <p className="flex">
                    Chef Price <img className="item-sort" src={sort} />
                  </p>
                </th>
                <th
                  className="pointer nowrap"
                  onClick={() => this.sortMenuItemsByNumber('marketPrice')}
                >
                  <p className="flex">
                    Market Price <img className="item-sort" src={sort} />
                  </p>
                </th>
                <th
                  className="pointer nowrap"
                  onClick={() => this.sortMenuItemsByMargin()}
                >
                  <p className="flex">
                    Margin % <img className="item-sort" src={sort} />
                  </p>
                </th>
                <th
                  className="pointer nowrap"
                  onClick={() => this.sortMenuItemsByBoolean('isEnabled')}
                >
                  <p className="flex">
                    Marketplace Enabled
                    <img className="item-sort" src={sort} />
                  </p>
                </th>
                <th
                  className="pointer nowrap"
                  onClick={() => this.sortMenuItemsByBoolean('isApproved')}
                >
                  <p className="flex">
                    Approved <img className="item-sort" src={sort} />
                  </p>
                </th>
                <th
                  className="flex flex-row"
                  onClick={() => this.sortMenuItemsByString('inputType')}
                >
                  <div style={{ display: 'flex', marginBottom: '10px' }}>
                    <HiInformationCircle
                      onMouseOver={() => this.renderInputDeails()}
                      fontSize="15px"
                    />
                  </div>
                  <p style={{ display: 'flex', paddingTop: '5px' }}>
                    Input Type{' '}
                  </p>
                  <img className="item-sort" src={sort} />
                </th>
                <th
                  className="pointer nowrap"
                  onClick={() => this.sortMenuItemsByString('addedBy')}
                >
                  <p className="flex">
                    Added By <img className="item-sort" src={sort} />
                  </p>
                </th>
                <th className="pointer nowrap">
                  {' '}
                  <p className="flex">Copy</p>{' '}
                </th>
                <th className="pointer nowrap">
                  <AuthorizedDisplay roles={['master admin', 'chef lead']}>
                    <p className="flex">
                      {viewArchivedMenuItems ? 'Bulk Restore' : 'Bulk Archive'}
                    </p>
                  </AuthorizedDisplay>
                </th>
              </tr>
            </thead>
            <tbody>
              {showLoader && (
                <tr>
                  <td colSpan="4">
                    <LoadingIndicator />
                  </td>
                </tr>
              )}

              {areMenuItemsLoaded && areMenuItemsEmpty && (
                <tr>
                  <td colSpan="5">
                    <div
                      style={{
                        textAlign: 'center',
                        fontSize: '1.2em',
                        color: 'rgba(0,0,0,0.2)',
                        padding: '20px',
                        backgroundColor: 'rgba(0,0,0,0.01)',
                      }}
                    >
                      No menu items yet.
                    </div>
                  </td>
                </tr>
              )}

              {areMenuItemsLoaded &&
                !showLoader &&
                displayedMenuItems.map((menuItem) => {
                  let backgroundColor = menuItem.edited ? 'lightblue' : null
                  if (menuItem.inputType === 'Custom') {
                    backgroundColor = '#FFFF00'
                  }
                  const { averageRating, numRatings } =
                    this.state.menuItemRatingsMap[menuItem.id] || {}

                  return (
                    <tr
                      key={menuItem.id}
                      className="clickable"
                      style={{ backgroundColor: backgroundColor }}
                    >
                      <td>
                        {(this.state.menuItemRatingsMap[menuItem.id] && (
                          <Fragment>
                            <p>
                              {numRatings !== 0
                                ? `${averageRating.toFixed(2)} ⭐`
                                : 'N/A'}
                            </p>
                            <p>{numRatings !== 0 ? `(${numRatings})` : ''}</p>
                          </Fragment>
                        )) ||
                          (menuItem.marketType === 'Group Order'
                            ? 'loading...'
                            : '')}
                      </td>
                      <td onClick={this.selectMenuItem(menuItem)}>
                        {menuItem.name}
                      </td>
                      <td onClick={this.selectMenuItem(menuItem)}>
                        {menuItem.marketType}
                      </td>
                      <td onClick={() => this.handleChangingDishType(menuItem)}>
                        {menuItem.mealType}
                      </td>
                      <td onClick={this.selectMenuItem(menuItem)}>
                        {menuItem.tagsGroupOrderMealTypeList &&
                          menuItem.tagsGroupOrderMealTypeList
                            .split(',')
                            .map((goMealType) => (
                              <span
                                key={`tag_${goMealType}`}
                                className="badge badge-cuisine"
                              >
                                {' '}
                                {goMealType}{' '}
                              </span>
                            ))}
                      </td>
                      <td onClick={this.selectMenuItem(menuItem)}>
                        {menuItem.tagsCuisineList &&
                          menuItem.tagsCuisineList.split(',').map((cuisine) => (
                            <span
                              key={`tag_${cuisine}`}
                              className="badge badge-cuisine"
                            >
                              {' '}
                              {cuisine}{' '}
                            </span>
                          ))}
                      </td>
                      <td>
                        <Checkbox
                          checked={menuItem.isHot}
                          onChange={(e) =>
                            this.onChangeEditedItems(
                              e.target.checked,
                              menuItem.id,
                              'isHot',
                            )
                          }
                        />
                      </td>
                      <td>
                        <CurrencyInput
                          value={menuItem.chefPrice}
                          onChange={(value) =>
                            this.onChangeEditedItems(
                              `${value}`,
                              menuItem.id,
                              'chefPrice',
                            )
                          }
                        />
                      </td>
                      <td>
                        <CurrencyInput
                          value={menuItem.marketPrice}
                          onChange={(value) =>
                            this.onChangeEditedItems(
                              `${value}`,
                              menuItem.id,
                              'marketPrice',
                            )
                          }
                        />
                      </td>
                      <td>{`${this.calcMargin(menuItem).toFixed(2)}%`}</td>
                      <td>
                        <AuthorizedInteractable
                          roles={['master admin', 'chef lead']}
                        >
                          <Checkbox
                            checked={menuItem.isEnabled}
                            onChange={(e) =>
                              this.onChangeEditedItems(
                                e.target.checked,
                                menuItem.id,
                                'isEnabled',
                              )
                            }
                          />
                        </AuthorizedInteractable>
                      </td>
                      <td>
                        <AuthorizedInteractable
                          roles={['master admin', 'chef lead']}
                        >
                          <Checkbox
                            checked={menuItem.isApproved}
                            onChange={(e) =>
                              this.onChangeEditedItems(
                                e.target.checked,
                                menuItem.id,
                                'isApproved',
                              )
                            }
                          />
                        </AuthorizedInteractable>
                      </td>
                      <td onClick={this.selectMenuItem(menuItem)}>
                        {menuItem.inputType ? menuItem.inputType : 'Manual'}
                      </td>
                      <td onClick={this.selectMenuItem(menuItem)}>
                        {menuItem.addedBy ? menuItem.addedBy : ''}
                      </td>
                      <td>
                        <AuthorizedInteractable
                          roles={['master admin', 'chef lead']}
                        >
                          <LinkText
                            label="Copy"
                            onClick={(e) => {
                              e.preventDefault()
                              this.props.showCopyMenuItem(menuItem.id)
                            }}
                          />
                        </AuthorizedInteractable>
                      </td>
                      <td>
                        <AuthorizedDisplay
                          roles={['master admin', 'chef lead']}
                        >
                          <Checkbox
                            checked={
                              !!checkedMenuItems.find(
                                (item) => item.id === menuItem.id,
                              )
                            }
                            onChange={this.onCheckMenuItem(
                              menuItem.id,
                              menuItem.name,
                            )}
                          />
                        </AuthorizedDisplay>
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </Table>
        </Panel>
        <div className="page-fixed">
          {itemsChecked && (
            <AuthorizedInteractable roles={['master admin', 'chef lead']}>
              <button
                className="button-primary"
                key="removeItem"
                onClick={this.handleBulk}
              >
                {viewArchivedMenuItems
                  ? 'Restore Checked Items'
                  : 'Archive Checked Items'}
              </button>
            </AuthorizedInteractable>
          )}
          {editedItems.length ? (
            <AuthorizedInteractable roles={['master admin', 'chef lead']}>
              <button
                className="button-primary"
                key="saveItems"
                disabled={this.state.isSavingEdits}
                onClick={() =>
                  this.setState({ isSavingEdits: true }, this.saveMenuItems)
                }
              >
                Save Menu Items
              </button>
            </AuthorizedInteractable>
          ) : null}
          {editedItems.length ? (
            <AuthorizedInteractable roles={['master admin', 'chef lead']}>
              <button
                className="button-primary"
                key="saveItems"
                onClick={this.clearEdits}
              >
                Clear Edits
              </button>
            </AuthorizedInteractable>
          ) : null}
        </div>
      </div>
    )
  }
}

MenuItems.propTypes = {
  chefId: PropTypes.string,
  menuItems: PropTypes.arrayOf(PropTypes.object),
  showEditItemModal: PropTypes.bool,
  showCopyMenuItemModal: PropTypes.bool,
  showCopyMenuItemsModal: PropTypes.bool,

  bulkRestoreMenuItems: PropTypes.func,
  deleteMenuItems: PropTypes.func,
  editMenuItem: PropTypes.func,
  loadChefMenuItems: PropTypes.func,
  loadMenuItemRatings: PropTypes.func,
  loadMenuItem: PropTypes.func,
  loadMenuItemSettings: PropTypes.func,
  newMenuItem: PropTypes.func,
  pToMenuItemVerifications: PropTypes.func,
  showCopyMenuItem: PropTypes.func,
  saveMenuItem: PropTypes.func,
  copyMenuItems: PropTypes.func,
}

export default MenuItems
