import React, { useState, useEffect, useCallback, ChangeEvent } from 'react'
import Moment from 'moment-timezone'
import { IoCaretForwardCircle, IoCaretDownCircle } from 'react-icons/io5'

import Modal from '@components/common/modal/Modal'
import {
  FlexContainer,
  YSpacing,
  DividerLine,
  XSpacing,
} from '@components/common'
import { Label } from '@res/styledComponents/index'
import {
  MarketDropdown,
  AutocompleteInput,
  TextInput,
  Dropdown,
  MultiSelectDropDown,
  Checkbox,
  Button,
  Input,
  CurrencyInput,
  TimeInput,
  TextArea,
} from '@components/common/form'
import CustomizedSettingsSection from './CustomizedSettingsSection'

import {
  ApiDinerProfile,
  DinerProfile,
  IndexHeadquarter,
  Account,
  Contact,
  Address,
  CustomOrderSettings,
  ApiCustomOrderSettings,
  ClientReportingProgram,
} from '@types'

import { AVERAGE_PRICE_PER_PERSON_TYPES, colors } from '@constants'

interface EditDinerProfileModalProps {
  user: { name: string; locale: string; lastLoggedInHq: number }
  account: Account
  clientReportingPrograms: ClientReportingProgram[]
  dinerProfile: DinerProfile
  saveDinerProfile: (profile: DinerProfile) => Promise<DinerProfile | null>
  closeModal: () => void
  loadHeadquarters: () => Promise<IndexHeadquarter[]>
  loadDinerProfiles: (
    params: Record<string, string | number[] | null>,
  ) => Promise<ApiDinerProfile[]>
  loadAccountDinerProfiles: (
    accountId: string,
    contacts: Contact[],
    addresses: Address[],
  ) => Promise<DinerProfile[]>
  loadOrderSettings: () => Promise<{
    allOrderTypes: { value: string; text: string }[]
  }>
  loadConceptsSettings: () => Promise<{ allCuisines: string[] }>
  loadMenuItemSettings: () => Promise<{ allDietaryPreferences: string[] }>

  flashError: (message: string) => void
  pCustomOrderSettings: (
    settings: ApiCustomOrderSettings,
  ) => CustomOrderSettings
}

type Option = {
  value: string
  label: string
}

const EditDinerProfileModal: React.FC<EditDinerProfileModalProps> = ({
  user,
  account,
  dinerProfile,
  clientReportingPrograms,
  saveDinerProfile,
  closeModal,
  loadHeadquarters,
  loadDinerProfiles,
  loadAccountDinerProfiles,
  loadOrderSettings,
  loadConceptsSettings,
  loadMenuItemSettings,
  flashError,
  pCustomOrderSettings,
}) => {
  const [profile, setProfile] = useState<DinerProfile>({ ...dinerProfile })
  const [reportingProgram, setReportingProgram] =
    useState<ClientReportingProgram | null>(null)
  const [hqIds, setHqIds] = useState<number[] | null>(null)
  const [headquarters, setHeadquarters] = useState<IndexHeadquarter[]>([])

  const [allOrderTypes, setAllOrderTypes] = useState<Option[]>([])
  const [allCuisines, setAllCuisines] = useState<Option[]>([])
  const [allDietaryPreferences, setAllDietaryPreferences] = useState<Option[]>(
    [],
  )
  const [showCustomSettings, setShowCustomSettings] = useState(false)

  useEffect(() => {
    const loadHqs = async () => {
      const hqData = await loadHeadquarters()
      setHeadquarters(hqData)
    }

    loadHqs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!dinerProfile.id) {
      return
    }

    const program = clientReportingPrograms.find(
      (p) => dinerProfile.id && p.dinerProfileIds.includes(dinerProfile.id),
    )
    if (program) {
      setReportingProgram(program)
    }
  }, [dinerProfile.id, clientReportingPrograms])

  useEffect(() => {
    Promise.all([
      loadOrderSettings(),
      loadConceptsSettings(),
      loadMenuItemSettings(),
    ]).then(([orderSettings, conceptSettings, menuItemSettings]) => {
      setAllOrderTypes(
        orderSettings.allOrderTypes
          .map((ot) => ({
            value: ot.value,
            label: ot.text,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
      )
      setAllCuisines(
        conceptSettings.allCuisines
          .map((c) => ({ value: c, label: c }))
          .sort((a, b) => a.label.localeCompare(b.label)),
      )
      setAllDietaryPreferences(
        menuItemSettings.allDietaryPreferences
          .map((dp) => {
            const cleaned = dp.replace('contains ', '')

            return {
              value: cleaned,
              label: cleaned,
            }
          })
          .sort((a, b) => a.label.localeCompare(b.label)),
      )
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadProfiles = useCallback(
    async (search: string) => {
      if (!hqIds) {
        return []
      }

      return loadDinerProfiles({ hqIds, search })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hqIds],
  )

  const renderProfileOption = (profile: ApiDinerProfile) => {
    const marketString =
      headquarters.find((hq) => hq.id === profile.hqId)?.prefix || 'N/A'

    return (
      <div
        key={profile.id}
        className="cursor-pointer hover:bg-gray-200 p-2"
        onClick={() => onSelectProfile(profile)}
      >
        <span className="font-semibold">{profile.name}</span>{' '}
        {`[Market: ${marketString}; Created ${Moment(profile.createdAt).format(
          'MM/DD/YYYY',
        )}]`}
      </div>
    )
  }

  const onSelectProfile = (copyProfile: ApiDinerProfile) => {
    // in case the dp is not associated with this account
    const { contactId, addressId, customOrderSettings, ...rest } = copyProfile
    const contact = account.contacts.find((c) => c.id === contactId)
    const address = account.addresses.find((a) => a.id === addressId)

    setProfile({
      ...rest,
      contact: contact || ({} as Contact),
      address: address || ({} as Address),
      id: dinerProfile.id,
      accountId: account.id,
      name: `${copyProfile.name} (Copy)`,
      isDefault: false,
      hqId: user.lastLoggedInHq,
      customOrderSettings:
        customOrderSettings && pCustomOrderSettings(customOrderSettings),
    })
  }

  const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value
    setProfile({ ...profile, name })
  }

  const onChangeMealType = (e: ChangeEvent<HTMLSelectElement>) => {
    const mealType = e.target.value
    setProfile({ ...profile, mealType })
  }

  const onChangeEventTime = (value: Moment.Moment) => {
    const { locale } = user
    const m = Moment(value).tz(locale)
    const pickupTime = m.hour() * 100 + m.minute()
    setProfile({ ...profile, eventTime: pickupTime })
  }

  const pEventTime = (time: number) =>
    Moment(time)
      .tz(user.locale)
      .set({
        h: time / 100,
        m: time % 100,
        s: 0,
      })

  const onChangeHeadcount = (e: ChangeEvent<HTMLInputElement>) => {
    const headcount = parseInt(e.target.value)
    setProfile({ ...profile, headcount })
  }

  const onChangeAddress = (e: ChangeEvent<HTMLSelectElement>) => {
    const address = account.addresses.find(
      (address) => address.id === e.target.value,
    )

    if (address) {
      setProfile({ ...profile, address })
    }
  }

  const addressLabel = (address: {
    line1: string
    city: string
    state: string
    zip: string
  }) => {
    return `${address.line1}, ${address.city}, ${address.state}, ${address.zip}`
  }

  const onChangeContact = (e: ChangeEvent<HTMLSelectElement>) => {
    const contact = account.contacts.find(
      (contact) => contact.id === e.target.value,
    )

    if (contact) {
      setProfile({ ...profile, contact })
    }
  }

  const validateStaffingFee = () => {
    const { customOrderSettings } = profile
    if (!customOrderSettings) {
      return true
    }

    const { needStaffAndServe, staffCount, staffHours, staffRate, staffFee } =
      customOrderSettings || {}

    if (needStaffAndServe === null || !needStaffAndServe) {
      return true
    } else if (
      staffCount !== null &&
      staffHours !== null &&
      staffRate !== null &&
      staffFee !== null
    ) {
      return staffFee === staffCount * staffHours * staffRate
    } else {
      return false
    }
  }

  const onSaveProfile = async () => {
    if (profile.customOrderSettings) {
      const requiredFieldErrors: string[] = []
      const { gratuity, gratuityType } = profile.customOrderSettings
      if (gratuity === null && gratuityType) {
        requiredFieldErrors.push(
          'Cannot set gratuity type when default gratuity is not set',
        )
      }
      if (gratuity !== null && !gratuityType) {
        requiredFieldErrors.push(
          'Cannot set default gratuity when gratuity type is not set',
        )
      }

      if (!validateStaffingFee()) {
        requiredFieldErrors.push('Invalid values for staffing fee')
      }

      if (requiredFieldErrors.length > 0) {
        const errorList =
          'Please check the following errors:\n' +
          '• ' +
          requiredFieldErrors.join('.\n• ') +
          '.'
        flashError(errorList)

        return
      }
    }

    profile.lastUpdatedBy = `${user.name}`
    const updatedProfile = await saveDinerProfile(profile)
    if (updatedProfile) {
      await loadAccountDinerProfiles(
        updatedProfile.accountId,
        account.contacts,
        account.addresses,
      )
      closeModal()
    }
  }

  const onChangeCustomOrderSetting = (
    key: keyof CustomOrderSettings,
    value: number | string | Moment.Moment | null,
  ) => {
    const { customOrderSettings, ...rest } = profile
    const newSettings: CustomOrderSettings = {
      ...(customOrderSettings || ({} as CustomOrderSettings)),
      [key as keyof CustomOrderSettings]: value,
    }

    setProfile({
      ...rest,
      customOrderSettings: newSettings,
    })
  }

  const onChangeStaffingField =
    (key: string) => (e: ChangeEvent<HTMLInputElement>) => {
      const value: number = e.target.valueAsNumber
      const valueOrNull = isNaN(value) ? null : value

      const customOrderSettings =
        profile.customOrderSettings || ({} as CustomOrderSettings)
      const { staffCount, staffHours, staffRate } = customOrderSettings
      const newSettings = {
        ...customOrderSettings,
      }

      switch (key) {
        case 'cateringStaffCount':
          newSettings.staffCount = valueOrNull
          newSettings.staffFee = calcStaffFee(
            valueOrNull,
            staffHours,
            staffRate,
          )
          break
        case 'cateringStaffHours':
          newSettings.staffHours = valueOrNull
          newSettings.staffFee = calcStaffFee(
            staffCount,
            valueOrNull,
            staffRate,
          )
          break
        case 'cateringStaffRate':
          newSettings.staffRate = valueOrNull
          newSettings.staffFee = calcStaffFee(
            staffCount,
            staffHours,
            valueOrNull,
          )
          break
        default:
          break
      }

      setProfile({
        ...profile,
        customOrderSettings: newSettings,
      })
    }

  const calcStaffFee = (
    count: number | null,
    hours: number | null,
    rate: number | null,
  ) => {
    if (count === null || hours === null || rate === null) {
      return null
    }

    return count * hours * rate
  }

  const onChangeNeedStaffAndServe = (e: ChangeEvent<HTMLInputElement>) => {
    const needStaffAndServe = e.target.checked
    setProfile({
      ...profile,
      customOrderSettings: {
        ...(profile.customOrderSettings || ({} as CustomOrderSettings)),
        needStaffAndServe,
      },
    })
  }

  const renderCustomSettings = () => {
    const { customOrderSettings } = profile
    const {
      gratuityType,
      gratuity,
      needStaffAndServe,
      staffCount,
      staffHours,
      staffRate,
      staffFee,
      deliveryAndServiceFeePercentWeekday,
      deliveryAndServiceFeeCapWeekday,
      deliveryAndServiceFeePercentWeekend,
      deliveryAndServiceFeeCapWeekend,
      deliveryAndServiceFeePercentHoliday,
      deliveryAndServiceFeeCapHoliday,
      doNotArriveBeforeTime,
    } = customOrderSettings || {}

    return (
      <FlexContainer flexDirection="column">
        <DividerLine margin="20px 0" />
        <CustomizedSettingsSection
          currencyDelay={10}
          gratuityType={gratuityType}
          gratuity={gratuity}
          needStaffAndServe={needStaffAndServe}
          cateringStaffCount={staffCount}
          cateringStaffHours={staffHours}
          cateringStaffRate={staffRate}
          cateringStaffFee={staffFee}
          doNotArriveBeforeTime={doNotArriveBeforeTime}
          deliveryAndServiceFeePercentWeekday={
            deliveryAndServiceFeePercentWeekday
          }
          deliveryAndServiceFeeCapWeekday={deliveryAndServiceFeeCapWeekday}
          deliveryAndServiceFeePercentWeekend={
            deliveryAndServiceFeePercentWeekend
          }
          deliveryAndServiceFeeCapWeekend={deliveryAndServiceFeeCapWeekend}
          deliveryAndServiceFeePercentHoliday={
            deliveryAndServiceFeePercentHoliday
          }
          deliveryAndServiceFeeCapHoliday={deliveryAndServiceFeeCapHoliday}
          onChange={onChangeCustomOrderSetting}
          onChangeStaffingFeeFields={onChangeStaffingField}
          onChangeCheckbox={onChangeNeedStaffAndServe}
          renderLastUpdated={() => null}
          skipValues={{ setUpCompleteByTime: true }}
        />
      </FlexContainer>
    )
  }

  return (
    <Modal
      title={
        dinerProfile.id
          ? `Edit Diner Profile: ${dinerProfile.name}`
          : 'Add New Diner Profile'
      }
      hideModal={closeModal}
      color="#001940"
      width="900px"
    >
      <div className="flex flex-row justify-between">
        <Label fontSize="16px">Copy Existing Diner Profile</Label>
        {profile.lastUpdatedBy && profile.lastUpdatedBy !== '' && (
          <p>
            Last Updated By: {profile.lastUpdatedBy}{' '}
            {Moment(profile.updatedAt).format('MM/DD/YYYY')}
          </p>
        )}
      </div>
      <p className="mt-5 mb-3" style={{ fontStyle: 'italic' }}>
        *Select market in order to search diner profiles
      </p>
      <FlexContainer flexDirection="row" width="100%">
        <div style={{ width: '30%' }}>
          <MarketDropdown
            options={headquarters}
            onSelectHq={(id) => {
              const hqs = hqIds ? [...hqIds, id] : [id]
              setHqIds(hqs)
            }}
            onDeselectHq={(id) => {
              const hqs = hqIds ? hqIds.filter((hqId) => hqId !== id) : null
              setHqIds(hqs)
            }}
            selectedOptions={hqIds || []}
          />
        </div>
        <div style={{ width: '33%', marginLeft: '10%' }}>
          <AutocompleteInput
            disabled={!hqIds}
            placeholder="Select market, then search by name"
            loaderFunction={({ search }: { search: string }) =>
              loadProfiles(search)
            }
            optionRenderFunction={renderProfileOption}
          />
        </div>
      </FlexContainer>
      <YSpacing height="20px" />
      <FlexContainer flexDirection="row" alignItems="center" width="100%">
        <div style={{ width: '45%' }}>
          <Label>*Diner Profile Name</Label>
          <TextInput
            testId="diner-profile-name"
            label="Diner Profile Name"
            value={profile.name}
            onChange={onChangeName}
          />
        </div>
        <p style={{ marginLeft: '10%', fontStyle: 'italic' }}>
          Create a distinct diner profile name to capture this group of diners.
          Diner profiles can be used across multiple clients, locations, and
          contacts.
        </p>
      </FlexContainer>
      <YSpacing height="20px" />
      <FlexContainer
        flexDirection="row"
        alignItems="center"
        width="100%"
        justifyContent="space-between"
      >
        <div>
          <Label>*Meal Type</Label>
          <Dropdown
            value={profile.mealType || ''}
            onChange={onChangeMealType}
            testId="diner-profile-meal-type"
          >
            <option value="">Select meal type...</option>
            {allOrderTypes.map((orderType) => (
              <option key={orderType.value} value={orderType.value}>
                {orderType.label}
              </option>
            ))}
          </Dropdown>
        </div>
        <div>
          <TimeInput
            testId="diner-profile-event-time"
            label="*Default Event Time"
            time={profile.eventTime ? pEventTime(profile.eventTime) : undefined}
            onChange={onChangeEventTime}
          />
        </div>
        <div>
          <Label>*Default Headcount</Label>
          <Input
            testId="diner-profile-headcount"
            type="number"
            value={profile.headcount?.toString() || ''}
            onChange={onChangeHeadcount}
          />
        </div>
      </FlexContainer>
      <YSpacing height="20px" />
      <FlexContainer
        flexDirection="row"
        width="100%"
        justifyContent="space-between"
      >
        <Label>*Default Delivery Address</Label>
        <Dropdown
          testId="diner-profile-address"
          width="50%"
          value={profile.address?.id || ''}
          onChange={onChangeAddress}
        >
          <option value="">Select an address...</option>
          {account.addresses.map((address) => (
            <option key={address.id} value={address.id}>
              {addressLabel(address)}
            </option>
          ))}
        </Dropdown>
      </FlexContainer>
      <YSpacing height="20px" />
      <FlexContainer
        flexDirection="row"
        width="100%"
        justifyContent="space-between"
      >
        <Label>*Default Client Contact</Label>
        <Dropdown
          testId="diner-profile-contact"
          width="50%"
          value={profile.contact?.id || ''}
          onChange={onChangeContact}
        >
          <option value="">Select a contact...</option>
          {account.contacts.map((contact) => (
            <option key={contact.id} value={contact.id}>
              {contact.name}
            </option>
          ))}
        </Dropdown>
      </FlexContainer>
      {reportingProgram && (
        <>
          <YSpacing height="20px" />
          <FlexContainer
            width="100%"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Label>Linked Reporting Program</Label>
            <FlexContainer width="50%">
              <p
                className="px-2 py-1 rounded-lg text-white font-semibold"
                style={{ backgroundColor: colors.blue }}
              >
                {reportingProgram.name}
              </p>
            </FlexContainer>
          </FlexContainer>
        </>
      )}
      <YSpacing height="20px" />
      <FlexContainer alignItems="center">
        <h2 className="sidebar-body-heading">
          Customized Settings for Catering Orders
        </h2>
        <XSpacing width="20px" />
        {showCustomSettings ? (
          <IoCaretForwardCircle
            color={colors.orange}
            size={25}
            onClick={() => setShowCustomSettings(false)}
          />
        ) : (
          <IoCaretDownCircle
            color={colors.orange}
            size={25}
            onClick={() => setShowCustomSettings(true)}
          />
        )}
      </FlexContainer>
      {showCustomSettings && renderCustomSettings()}
      <DividerLine margin="20px 0" />
      <p style={{ color: '#E05267' }} className="font-bold">
        For Smart Recommendations ✨
      </p>
      <YSpacing height="20px" />
      <FlexContainer
        flexDirection="row"
        width="67%"
        justifyContent="space-between"
      >
        <div>
          <Label>Budget Per Employee</Label>
          <CurrencyInput
            width="100%"
            delay={10} // ?????????????????????????? without this delay, copying a profile will cause this onChange to fire and leave us with initial state + copied value
            value={profile.budgetPerEmployee}
            onChange={(value: number) => {
              setProfile({
                ...profile,
                budgetPerEmployee: value,
              })
            }}
          />
        </div>
        <div>
          <Label>Budget Per Employee Type</Label>
          <Dropdown
            width="100%"
            value={profile.budgetPerEmployeeType || ''}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
              setProfile({ ...profile, budgetPerEmployeeType: e.target.value })
            }}
          >
            <option key={-1} value="">
              Select
            </option>
            {AVERAGE_PRICE_PER_PERSON_TYPES.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </Dropdown>
        </div>
      </FlexContainer>
      <YSpacing height="20px" />
      <FlexContainer width="100%" justifyContent="space-between">
        <FlexContainer flexDirection="column" width="45%">
          <Label>Dietary Preferences & Known Allergens</Label>
          <YSpacing height="10px" />
          <Checkbox
            label="Must Include"
            checked={profile.mustIncludeDietPrefsActive}
            onChange={() =>
              setProfile({
                ...profile,
                mustIncludeDietPrefsActive: !profile.mustIncludeDietPrefsActive,
              })
            }
          />
          <YSpacing height="5px" />
          <MultiSelectDropDown
            label=""
            width="100%"
            includeSelectAll={true}
            renderCheckedOptions={true}
            options={allDietaryPreferences}
            checkedOptions={profile.mustIncludeDietPrefs || []}
            searchPlaceholder="Type in dietary preferences or allergens"
            onChange={(newOptions) =>
              setProfile({ ...profile, mustIncludeDietPrefs: newOptions })
            }
          />
          <YSpacing height="7px" />
          <Checkbox
            label="Must Exclude"
            checked={profile.mustExcludeDietPrefsActive}
            onChange={() =>
              setProfile({
                ...profile,
                mustExcludeDietPrefsActive: !profile.mustExcludeDietPrefsActive,
              })
            }
          />
          <YSpacing height="5px" />
          <MultiSelectDropDown
            label=""
            width="100%"
            includeSelectAll={true}
            renderCheckedOptions={true}
            options={allDietaryPreferences}
            checkedOptions={profile.mustExcludeDietPrefs || []}
            searchPlaceholder="Type in dietary preferences or allergens"
            onChange={(newOptions) =>
              setProfile({ ...profile, mustExcludeDietPrefs: newOptions })
            }
          />
        </FlexContainer>
        <FlexContainer flexDirection="column" width="45%">
          <Label>Cuisine Preferences</Label>
          <YSpacing height="10px" />
          <Checkbox
            label="Must Include"
            checked={profile.mustIncludeCuisinesActive}
            onChange={() =>
              setProfile({
                ...profile,
                mustIncludeCuisinesActive: !profile.mustIncludeCuisinesActive,
              })
            }
          />
          <YSpacing height="5px" />
          <MultiSelectDropDown
            label=""
            width="100%"
            includeSelectAll={true}
            renderCheckedOptions={true}
            options={allCuisines}
            checkedOptions={profile.mustIncludeCuisines || []}
            searchPlaceholder="Type in cuisine"
            onChange={(newOptions) =>
              setProfile({ ...profile, mustIncludeCuisines: newOptions })
            }
          />
          <YSpacing height="7px" />
          <Checkbox
            label="Must Exclude"
            checked={profile.mustExcludeCuisinesActive}
            onChange={() =>
              setProfile({
                ...profile,
                mustExcludeCuisinesActive: !profile.mustExcludeCuisinesActive,
              })
            }
          />
          <YSpacing height="5px" />
          <MultiSelectDropDown
            label=""
            width="100%"
            includeSelectAll={true}
            renderCheckedOptions={true}
            options={allCuisines}
            checkedOptions={profile.mustExcludeCuisines || []}
            searchPlaceholder="Type in cuisine"
            onChange={(newOptions) =>
              setProfile({ ...profile, mustExcludeCuisines: newOptions })
            }
          />
        </FlexContainer>
      </FlexContainer>
      <DividerLine margin="20px 0" />
      <FlexContainer
        width="100%"
        justifyContent="space-between"
        alignItems="center"
      >
        <Checkbox
          label="Add Default Chef Notes"
          checked={profile.addChefNotes}
          onChange={() =>
            setProfile({ ...profile, addChefNotes: !profile.addChefNotes })
          }
        />
        <p style={{ marginLeft: '10%', fontStyle: 'italic', width: '100%' }}>
          Activating this feature will have your default chef notes displayed on
          every order created for this Diner Profile.
        </p>
      </FlexContainer>
      <div className="mt-2">
        <TextArea
          value={profile.chefNotes}
          height="100px"
          placeholder='Example: "Please note: This client has a peanut & shellfish allergy present"'
          onChange={(e) =>
            setProfile({ ...profile, chefNotes: e.target.value })
          }
        />
      </div>
      <DividerLine margin="20px 0" />
      <FlexContainer width="100%" justifyContent="flex-end">
        <FlexContainer width="33%" justifyContent="space-between">
          <Button
            testId="save-diner-profile"
            label="Save"
            backgroundColor="#6580CC"
            onClick={onSaveProfile}
            disabled={!profile.address?.id || !profile.contact?.id}
          />
          <Button label="Cancel" onClick={closeModal} />
        </FlexContainer>
      </FlexContainer>
    </Modal>
  )
}

export default EditDinerProfileModal
