import { DinerProfile, ApiDinerProfile, Contact, Address } from '@types'

interface LoadAccountDinerProfilesCoordinator {
  RestService: {
    get: (path: string) => Promise<ApiDinerProfile[]>
  }
  AccountService: {
    setDinerProfiles: (profiles: DinerProfile[]) => void
  }
  HandleError: any
  pResponseDinerProfiles: (
    profile: ApiDinerProfile[],
    contacts: Contact[],
    addresses: Address[],
  ) => DinerProfile[]
}
export const LoadAccountDinerProfiles =
  ({
    RestService,
    AccountService,
    HandleError,
    pResponseDinerProfiles,
  }: LoadAccountDinerProfilesCoordinator) =>
  async (accountId: string, contacts: Contact[], addresses: Address[]) => {
    try {
      const dinerProfiles = await RestService.get(
        `/accounts/${accountId}/diner-profiles`,
      )
      const profiles = pResponseDinerProfiles(
        dinerProfiles,
        contacts,
        addresses,
      )
      AccountService.setDinerProfiles(profiles)

      return profiles
    } catch (error) {
      HandleError({ error })

      return []
    }
  }

interface LoadDinerProfileCoordinator {
  RestService: {
    get: (path: string) => Promise<ApiDinerProfile>
  }
  HandleError: any
}
export const LoadDinerProfile =
  ({ RestService, HandleError }: LoadDinerProfileCoordinator) =>
  async (id: string) => {
    try {
      const dinerProfile = await RestService.get(
        `/accounts/diner-profiles/${id}`,
      )

      return dinerProfile
    } catch (error) {
      HandleError({ error })

      return null
    }
  }

interface LoadDinerProfilesParams {
  ids?: string[]
  hqId?: number
  hqIds?: number[]
  search?: string
  accountId?: string
}
interface LoadDinerProfilesCoordinator {
  RestService: {
    get: (
      path: string,
      params: LoadDinerProfilesParams,
    ) => Promise<ApiDinerProfile[]>
  }
  HandleError: any
}
export const LoadDinerProfiles =
  ({ RestService, HandleError }: LoadDinerProfilesCoordinator) =>
  async (params: LoadDinerProfilesParams) => {
    try {
      const req = {} as LoadDinerProfilesParams
      if (params.ids) {
        req.ids = params.ids
      }
      if (params.hqId) {
        req.hqId = params.hqId
      }
      if (params.search) {
        req.search = params.search
      }
      if (params.accountId) {
        req.accountId = params.accountId
      }
      if (params.hqIds) {
        req.hqIds = params.hqIds
      }

      const dinerProfiles = await RestService.get(
        `/accounts/diner-profiles`,
        params,
      )

      return dinerProfiles
    } catch (error) {
      HandleError({ error })

      return []
    }
  }

interface SaveDinerProfileCoordinator {
  RestService: {
    post: (path: string, req: ApiDinerProfile) => Promise<ApiDinerProfile>
    put: (path: string, req: ApiDinerProfile) => Promise<ApiDinerProfile>
  }
  AccountService: {
    clearEditDinerProfile: () => void
  }
  UIService: {
    EditDinerProfile: {
      close: () => void
    }
  }
  HandleError: any
  pRequestDinerProfile: (dinerProfile: DinerProfile) => ApiDinerProfile
}
export const SaveDinerProfile =
  ({
    RestService,
    AccountService,
    UIService,
    HandleError,
    pRequestDinerProfile,
  }: SaveDinerProfileCoordinator) =>
  async (req: DinerProfile) => {
    try {
      let profile
      if (req.id) {
        profile = await RestService.put(
          `/accounts/diner-profiles`,
          pRequestDinerProfile(req),
        )
      } else {
        profile = await RestService.post(
          `/accounts/diner-profiles`,
          pRequestDinerProfile(req),
        )
      }
      AccountService.clearEditDinerProfile()
      UIService.EditDinerProfile.close()

      return profile
    } catch (error) {
      HandleError({ error })

      return null
    }
  }

interface EditDinerProfileCoordinator {
  AccountService: {
    setEditDinerProfile: (profile: DinerProfile) => void
    clearEditDinerProfile: () => void
  }
  UIService: {
    EditDinerProfile: {
      show: () => void
      close: () => void
    }
  }
}
export const EditDinerProfile =
  ({ AccountService, UIService }: EditDinerProfileCoordinator) =>
  (profile: DinerProfile) => {
    AccountService.setEditDinerProfile(profile)
    UIService.EditDinerProfile.show()
  }

export const CloseEditDinerProfileModal =
  ({ AccountService, UIService }: EditDinerProfileCoordinator) =>
  () => {
    AccountService.clearEditDinerProfile()
    UIService.EditDinerProfile.close()
  }

interface MarkDefaultDinerProfileCoordinator {
  RestService: {
    put: (
      path: string,
      req: { accountId: string; profileId: string; updatedBy: string },
    ) => Promise<ApiDinerProfile[]>
  }
  AccountService: {
    setDinerProfiles: (profiles: DinerProfile[]) => void
  }
  HandleError: any
  pResponseDinerProfiles: (
    profile: ApiDinerProfile[],
    contacts: Contact[],
    addresses: Address[],
  ) => DinerProfile[]
}
export const MarkDefaultDinerProfile =
  ({
    RestService,
    AccountService,
    HandleError,
    pResponseDinerProfiles,
  }: MarkDefaultDinerProfileCoordinator) =>
  async (
    accountId: string,
    profileId: string,
    updatedBy: string,
    contacts: Contact[],
    addresses: Address[],
  ) => {
    try {
      const profiles = await RestService.put(
        '/accounts/diner-profiles/default',
        { accountId, profileId, updatedBy },
      )
      AccountService.setDinerProfiles(
        pResponseDinerProfiles(profiles, contacts, addresses),
      )

      return profiles
    } catch (error) {
      HandleError({ error })
    }
  }
